import React, { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import { useFormik } from "formik";
import axios from "axios";
import { validationSchema } from "../schema/TyreRetreadingSchema";
import Autocomplete from "@mui/material/Autocomplete";
import { apiRoutes } from "../utils/apiRoutes";
import AWS from "aws-sdk";

function TyreRetreading() {
  const [tyreNo, setTyreNo] = useState([]);
  const [truckNo, setTruckNo] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [invoiceImages, setInvoiceImages] = useState([]);
  const date = new Date();
  var convertedDate = date.toLocaleDateString().split("/").reverse().join("-");
  const {
    getTyreNumberAPI,
    getTruckNumberAPI,
    getVendorAPI,
    tyreRetreadingAPI,
  } = apiRoutes();

  useEffect(() => {
    async function getTyreNumber() {
      const res = await axios.get(getTyreNumberAPI);
      setTyreNo(res.data);
    }

    async function getTruckNumber() {
      const res = await axios.get(getTruckNumberAPI);
      setTruckNo(res.data);
    }

    async function getVendors() {
      const res = await axios.get(getVendorAPI);
      const data = res.data.map((item) => item.vendor_name);
      setVendors(data);
    }

    getTyreNumber();
    getTruckNumber();
    getVendors();
  }, []);

  const formik = useFormik({
    initialValues: {
      tyre_no: "",
      truck_no: "",
      vendor: "",
      retreading_date: convertedDate,
      tread_pattern: "",
      retreading_date_odometer: "",
      amount: "",
    },

    validationSchema: validationSchema,

    onSubmit: async (values, { resetForm }) => {
      const s3 = new AWS.S3({
        accessKeyId: "AKIAVRUVQC2QLL7YLPUJ",
        secretAccessKey: "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",
        region: "ap-south-1",
      });

      const locations = [];

      for (let i = 0; i < invoiceImages.length; i++) {
        const file = invoiceImages[i];
        const params = {
          Bucket: "alvision-srcc-images",
          Key: `tyre-retreading-invoice-images/${file.name}`,
          Body: file,
        };

        // Upload the file to S3 and wait for the promise to resolve
        const data = await s3.upload(params).promise();

        // Push the S3 location to the locations array
        locations.push(data.Location);
      }

      const res = await axios.post(tyreRetreadingAPI, {
        ...values,
        invoiceImages: locations,
      });
      resetForm();
      alert(res.data.message);
    },
  });

  const handleTyreNoChange = (event, value) => {
    formik.setFieldValue("tyre_no", value);
  };

  const handleTruckNoChange = (event, value) => {
    formik.setFieldValue("truck_no", value);
  };

  const handleVendorChange = (event, value) => {
    formik.setFieldValue("vendor", value);
  };

  return (
    <div>
      <div className="form">
        <h3>Enter tyre retreading details</h3>
        <form onSubmit={formik.handleSubmit}>
          <Autocomplete
            disablePortal
            options={Array.isArray(tyreNo) ? tyreNo : []}
            getOptionLabel={(option) => option}
            width="100%"
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select tyre number"
                error={formik.touched.tyre_no && Boolean(formik.errors.tyre_no)}
                helperText={formik.touched.tyre_no && formik.errors.tyre_no}
              />
            )}
            id="tyre_no"
            name="tyre_no"
            onChange={handleTyreNoChange}
            value={formik.values.tyre_no}
            style={{ marginBottom: "15px" }}
          />

          <Autocomplete
            disablePortal
            options={Array.isArray(truckNo) ? truckNo : []}
            getOptionLabel={(option) => option}
            width="100%"
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select truck number"
                error={
                  formik.touched.truck_no && Boolean(formik.errors.truck_no)
                }
                helperText={formik.touched.truck_no && formik.errors.truck_no}
              />
            )}
            id="truck_no"
            name="truck_no"
            onChange={handleTruckNoChange}
            value={formik.values.truck_no}
            style={{ marginBottom: "15px" }}
          />

          <Autocomplete
            disablePortal
            options={Array.isArray(vendors) ? vendors : []}
            getOptionLabel={(option) => option}
            width="100%"
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select vendor"
                error={formik.touched.vendor && Boolean(formik.errors.vendor)}
                helperText={formik.touched.vendor && formik.errors.vendor}
              />
            )}
            id="vendor"
            name="vendor"
            onChange={handleVendorChange}
            value={formik.values.vendor}
            style={{ marginBottom: "15px" }}
          />

          <TextField
            margin="dense"
            variant="outlined"
            fullWidth
            id="retreading_date"
            name="retreading_date"
            type="date"
            label="Retreading date"
            value={formik.values.retreading_date}
            onChange={formik.handleChange}
            error={
              formik.touched.retreading_date &&
              Boolean(formik.errors.retreading_date)
            }
            helperText={
              formik.touched.retreading_date && formik.errors.retreading_date
            }
          />

          <TextField
            margin="dense"
            variant="outlined"
            fullWidth
            id="tread_pattern"
            name="tread_pattern"
            label="Tread pattern"
            value={formik.values.tread_pattern}
            onChange={formik.handleChange}
            error={
              formik.touched.tread_pattern &&
              Boolean(formik.errors.tread_pattern)
            }
            helperText={
              formik.touched.tread_pattern && formik.errors.tread_pattern
            }
          />

          <TextField
            margin="dense"
            variant="outlined"
            fullWidth
            id="amount"
            name="amount"
            label="Amount"
            value={formik.values.amount}
            onChange={formik.handleChange}
            error={formik.touched.amount && Boolean(formik.errors.amount)}
            helperText={formik.touched.amount && formik.errors.amount}
          />

          <TextField
            margin="dense"
            variant="outlined"
            fullWidth
            id="retreading_date_odometer"
            name="retreading_date_odometer"
            label="Retreading date odometer"
            value={formik.values.retreading_date_odometer}
            onChange={formik.handleChange}
            error={
              formik.touched.retreading_date_odometer &&
              Boolean(formik.errors.retreading_date_odometer)
            }
            helperText={
              formik.touched.retreading_date_odometer &&
              formik.errors.retreading_date_odometer
            }
          />

          <label htmlFor="tyreBlast" className="uploadBtn-secondary">
            Upload invoice images
          </label>
          <input
            type="file"
            multiple
            id="tyreBlast"
            onChange={(e) => setInvoiceImages(e.target.files)}
            style={{ display: "none" }}
          />

          <br />

          <button className="submit-btn" type="submit">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
}

export default TyreRetreading;
