import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import "../styles/dashboard.scss";
import Vendor from "./dashboardComponents/Vendor";
import TyreBrand from "./dashboardComponents/TyreBrand";

function Dashboard(props) {
  return (
    <>
      <Container fluid className="dashboard">
        <Row className="dashboard-row">
          <Col
            xs={12}
            lg={6}
            className="dashboard-col"
            style={{ display: "flex" }}
          >
            <Vendor />
          </Col>
          <Col xs={12} lg={6} className="dashboard-col">
            <TyreBrand />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Dashboard;
