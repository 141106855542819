import React, { useEffect, useState } from "react";
import axios from "axios";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import LaptopMacIcon from "@mui/icons-material/LaptopMac";
import TextField from "@mui/material/TextField";
import { Container, Row, Col } from "react-bootstrap";
import { apiRoutes } from "../utils/apiRoutes";

function TyreDetails() {
  const [tyreNo, setTyreNo] = useState("");
  const [data, setData] = useState([]);
  const [truckDetails, setTruckDetails] = useState([]);
  const { tyreDetailsAPI } = apiRoutes();

  useEffect(() => {
    async function getData() {
      const res = await axios(`${tyreDetailsAPI}/${tyreNo}`);
      setData(res.data);
      setTruckDetails(res.data.trucks);
    }

    getData();
  }, [tyreNo]);

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h3 style={{ marginBottom: "20px" }}>Tyre Details</h3>
        <TextField
          id="outlined-basic"
          label="Enter a Tyre Number"
          variant="outlined"
          onChange={(e) => setTyreNo(e.target.value)}
        />
      </div>

      {data.length !== 0 && (
        <Container fluid>
          <Row style={{ marginTop: "50px" }}>
            <Col>
              <h4>Tyre Info</h4>
              <ul>
                <li>
                  <strong>Tyre Number: </strong>
                  {data.tyre_no}
                </li>

                <li>
                  <strong>Tyre Brand: </strong>
                  {data.tyre_brand}
                </li>

                <li>
                  <strong>Tyre Model: </strong>
                  {data.tyre_model}
                </li>

                <li>
                  <strong>Make: </strong>
                  {data.make}
                </li>

                <li>
                  <strong>Tyre Type: </strong>
                  {data.tyre_type}
                </li>

                <li>
                  <strong>Tyre Size: </strong>
                  {data.tyre_size}
                </li>

                <li>
                  <strong>Ply Rating: </strong>
                  {data.ply_rating}
                </li>
              </ul>
            </Col>

            <Col>
              <h4>Vendor info</h4>
              <ul>
                <li>
                  <strong>Vendor Name: </strong>
                  {data.vendor_name}
                </li>

                <li>
                  <strong>Vendor Address: </strong>
                  {data.vendor_address}
                </li>

                <li>
                  <strong>Vendor Phone: </strong>
                  {data.vendor_phone}
                </li>
              </ul>
            </Col>

            <Col>
              <h4>Tyre life</h4>
              {truckDetails.map((item, index) => {
                return (
                  <Timeline key={index}>
                    <TimelineItem>
                      <TimelineSeparator>
                        <TimelineDot color="primary">
                          <LaptopMacIcon />
                        </TimelineDot>
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent>
                        <h6>Truck number: {item.truck_no}</h6>
                        <span>
                          Distance:
                          {isNaN(
                            parseInt(item.removal_date_odometer) -
                              parseInt(item.fitting_date_odometer)
                          )
                            ? "" // Display an empty string if the result is NaN
                            : parseInt(item.removal_date_odometer) -
                              parseInt(item.fitting_date_odometer)}
                        </span>
                      </TimelineContent>
                    </TimelineItem>
                  </Timeline>
                );
              })}
            </Col>
          </Row>
        </Container>
      )}
    </div>
  );
}

export default TyreDetails;
