import React, { useEffect, useState } from "react";
import axios from "axios";
import { apiRoutes } from "../utils/apiRoutes";
import { DataGrid } from "@mui/x-data-grid";

function RTO() {
  const [data, setData] = useState([]);
  const { getRtoAPI } = apiRoutes();

  useEffect(() => {
    async function fetchRTO() {
      const res = await axios(getRtoAPI);
      setData(res.data);
    }
    fetchRTO();
  }, []);

  const columns = [
    {
      field: "_id",
      sortable: false,
      hide: true,
      headerName: "ID",
      align: "center",
    },
    {
      field: "fitness_document",
      sortable: false,
      headerName: "Fitness Document",
      width: 150,
      align: "center",
    },
    {
      field: "fitness_document_expiry_date",
      sortable: false,
      headerName: "Fitness Document Expiry Date",
      width: 220,
      align: "center",
    },
    {
      field: "inspection_due_date",
      sortable: false,
      headerName: "Inspection Due Date",
      width: 150,
      align: "center",
    },
    {
      field: "mv_tax",
      sortable: false,
      headerName: "MV Tax",
      width: 100,
      align: "center",
    },
    {
      field: "mv_tax_date",
      sortable: false,
      headerName: "MV Tax Date",
      width: 150,
      align: "center",
    },
    {
      field: "insurance_expiry_date",
      sortable: false,
      headerName: "Insurance Expiry Date",
      width: 200,
      align: "center",
    },
    {
      field: "puc_expiry_date",
      sortable: false,
      headerName: "PUC Expiry Date",
      width: 150,
      align: "center",
    },
    {
      field: "goods_permit_no",
      sortable: false,
      headerName: "Goods Permit No",
      width: 150,
      align: "center",
    },
    {
      field: "goods_permit_validity_date",
      sortable: false,
      headerName: "Goods Permit Validity Date",
      width: 200,
      align: "center",
    },
    {
      field: "national_permit_no",
      sortable: false,
      headerName: "National Permit No",
      width: 150,
      align: "center",
    },
    {
      field: "national_permit_validity_date",
      sortable: false,
      headerName: "National Permit Validity Date",
      width: 200,
      align: "center",
    },
    {
      field: "hp",
      sortable: false,
      headerName: "HP",
      width: 100,
      align: "center",
    },
    {
      field: "hp_financer_name",
      sortable: false,
      headerName: "HP Financer Name",
      width: 150,
      align: "center",
    },
    {
      field: "number_plate",
      sortable: false,
      headerName: "Number Plate",
      width: 150,
      align: "center",
    },
    {
      field: "supd",
      sortable: false,
      headerName: "SUPD",
      width: 150,
      align: "center",
    },

    {
      field: "fitness_document_photo",
      sortable: false,
      headerName: "Fitness Document Photo",
      width: 200,
      align: "center",
      renderCell: (params) => (
        <a
          href={params.row.fitness_document_photo}
          download="fitness_document_photo.png"
          style={{
            textDecoration: "underline",
            color: "blue",
            cursor: "pointer",
          }}
        >
          Download
        </a>
      ),
    },
    {
      field: "mv_tax_photo",
      sortable: false,
      headerName: "MV Tax Photo",
      width: 150,
      align: "center",
      renderCell: (params) => (
        <a
          href={params.row.mv_tax_photo}
          download="mv_tax_photo.png"
          style={{
            textDecoration: "underline",
            color: "blue",
            cursor: "pointer",
          }}
        >
          Download
        </a>
      ),
    },
    {
      field: "insurance_photo",
      sortable: false,
      headerName: "Insurance Photo",
      width: 150,
      align: "center",
      renderCell: (params) => (
        <a
          href={params.row.insurance_photo}
          download="insurance_photo.png"
          style={{
            textDecoration: "underline",
            color: "blue",
            cursor: "pointer",
          }}
        >
          Download
        </a>
      ),
    },
    {
      field: "puc_photo",
      sortable: false,
      headerName: "PUC Photo",
      width: 150,
      align: "center",
      renderCell: (params) => (
        <a
          href={params.row.puc_photo}
          download="puc_photo.png"
          style={{
            textDecoration: "underline",
            color: "blue",
            cursor: "pointer",
          }}
        >
          Download
        </a>
      ),
    },
    {
      field: "goods_permit_photo",
      sortable: false,
      headerName: "Goods Permit Photo",
      width: 150,
      align: "center",
      renderCell: (params) => (
        <a
          href={params.row.goods_permit_photo}
          download="goods_permit_photo.png"
          style={{
            textDecoration: "underline",
            color: "blue",
            cursor: "pointer",
          }}
        >
          Download
        </a>
      ),
    },
    {
      field: "national_permit_photo",
      sortable: false,
      headerName: "National Permit Photo",
      width: 150,
      align: "center",
      renderCell: (params) => (
        <a
          href={params.row.national_permit_photo}
          download="national_permit_photo.png"
          style={{
            textDecoration: "underline",
            color: "blue",
            cursor: "pointer",
          }}
        >
          Download
        </a>
      ),
    },
    {
      field: "rc_front_photo",
      sortable: false,
      headerName: "RC Front Photo",
      width: 150,
      align: "center",
      renderCell: (params) => (
        <a
          href={params.row.rc_front_photo}
          download="rc_front_photo.png"
          style={{
            textDecoration: "underline",
            color: "blue",
            cursor: "pointer",
          }}
        >
          Download
        </a>
      ),
    },
    {
      field: "rc_rear_photo",
      sortable: false,
      headerName: "RC Rear Photo",
      width: 150,
      align: "center",
      renderCell: (params) => (
        <a
          href={params.row.rc_rear_photo}
          download="rc_rear_photo.png"
          style={{
            textDecoration: "underline",
            color: "blue",
            cursor: "pointer",
          }}
        >
          Download
        </a>
      ),
    },
  ];

  return (
    <div>
      <DataGrid
        getRowId={(row) => row._id}
        sx={{
          height: "80%",
          "& .MuiDataGrid-row:hover": {
            backgroundColor: "#f8f5ff",
          },
        }}
        className="table expense-table"
        headerAlign="center"
        rows={data}
        columns={columns}
        stickyHeader
        rowsPerPageOptions={[25]}
        getRowHeight={() => "auto"}
        pageSize={25}
        height="80%"
        autoHeight={true}
        disableSelectionOnClick
      />
    </div>
  );
}

export default RTO;
