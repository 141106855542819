export function apiRoutes() {
  // localhost
  // const getTruckNumberAPI = "http://localhost:8000/api/getTruckNumber";
  // const getDriversAPI = "http://localhost:8000/api/getDrivers";
  // const driverAssignmentAPI = "http://localhost:8000/api/driverAssignment";
  // const getVendorAPI = "http://localhost:8000/api/getVendor";
  // const getTyreModelAPI = "http://localhost:8000/api/getTyreModel";
  // const getTyreBrandAPI = "http://localhost:8000/api/getTyreBrand";
  // const getTyreTypeAPI = "http://localhost:8000/api/getTyreType";
  // const getTyreSizeAPI = "http://localhost:8000/api/getTyreSize";
  // const getPlyRatingAPI = "http://localhost:8000/api/getPlyRating";
  // const insertNewTyreAPI = "http://localhost:8000/insertNewTyre";
  // const addVendorAPI = "http://localhost:8000/api/addVendor";
  // const addVehicleAPI = "http://localhost:8000/api/addVehicle";
  // const addTyreTypeAPI = "http://localhost:8000/api/addTyreType";
  // const addTyreSizeAPI = "http://localhost:8000/api/addTyreSize";
  // const addTyreModelAPI = "http://localhost:8000/api/addTyreModel";
  // const addTyreBrandAPI = "http://localhost:8000/api/addTyreBrand";
  // const addRepairTypeAPI = "http://localhost:8000/api/addRepairType";
  // const addPlyRatingAPI = "http://localhost:8000/api/addPlyRating";
  // const addDriverDetailsAPI = "http://localhost:8000/api/addDriverDetails";
  // const getTyreNumberAPI = "http://localhost:8000/api/getTyreNumber";
  // const tyreRetreadingAPI = "http://localhost:8000/api/tyreRetreading";
  // const tyreReplacementAPI = "http://localhost:8000/tyreReplacement";
  // const getRepairTypesAPI = "http://localhost:8000/api/getRepairType";
  // const addTyreRepairAPI = "http://localhost:8000/api/addTyreRepair";
  // const tyreFittingAPI = "http://localhost:8000/api/tyreFitting";
  // const truckTyresAPI = "http://localhost:8000/api/truckTyres";
  // const tyreBlastAPI = "http://localhost:8000/api/tyreBlast";
  // const tyreDetailsAPI = "http://localhost:8000/tyreDetails";
  // const rtoAPI = "http://localhost:8000/api/rto";
  // const getRtoAPI = "http://localhost:8000/api/getRto";
  // const getTruckDetailsAPI = "http://localhost:8000/api/getTruckDetails";
  // const restockEngineOilAPI = "http://localhost:8000/api/restockEngineOil";
  // const engineOilDistributionAPI =
  //   "http://localhost:8000/api/engineOilDistribution";
  // const getEngineOilStockAPI = "http://localhost:8000/api/getEngineOilStock";
  // const viewEngineOilAPI = "http://localhost:8000/api/viewEngineOil";
  // const addOrganisationMasterAPI =
  //   "http://localhost:8000/api/addOrganisationMaster";
  // const addLocationMasterAPI = "http://localhost:8000/api/addLocationMaster";
  // const addTypeOfVehicleAPI = "http://localhost:8000/api/addTypeOfVehicle";
  // const getTypeOfVehicleAPI = "http://localhost:8000/api/getTypeOfVehicle";
  // const addOrganisationAddressAPI =
  //   "http://localhost:8000/api/addOrganisationAddress";
  // const addContainerTypeAPI = "http://localhost:8000/api/addContainerType";
  // const getContainerTypesAPI = "http://localhost:8000/api/getContainerTypes";
  // const getLocationMastersAPI = "http://localhost:8000/api/getLocationMasters";

  /////////////////////////////////////////////////////////////////////////////////////////////////

  // // server
  const getTruckNumberAPI = "http://3.111.214.24:8000/api/getTruckNumber";
  const getDriversAPI = "http://3.111.214.24:8000/api/getDrivers";
  const driverAssignmentAPI = "http://3.111.214.24:8000/api/driverAssignment";
  const getVendorAPI = "http://3.111.214.24:8000/api/getVendor";
  const getTyreModelAPI = "http://3.111.214.24:8000/api/getTyreModel";
  const getTyreBrandAPI = "http://3.111.214.24:8000/api/getTyreBrand";
  const getTyreTypeAPI = "http://3.111.214.24:8000/api/getTyreType";
  const getTyreSizeAPI = "http://3.111.214.24:8000/api/getTyreSize";
  const getPlyRatingAPI = "http://3.111.214.24:8000/api/getPlyRating";
  const insertNewTyreAPI = "http://3.111.214.24:8000/insertNewTyre";
  const addVendorAPI = "http://3.111.214.24:8000/api/addVendor";
  const addVehicleAPI = "http://3.111.214.24:8000/api/addVehicle";
  const addTyreTypeAPI = "http://3.111.214.24:8000/api/addTyreType";
  const addTyreSizeAPI = "http://3.111.214.24:8000/api/addTyreSize";
  const addTyreModelAPI = "http://3.111.214.24:8000/api/addTyreModel";
  const addTyreBrandAPI = "http://3.111.214.24:8000/api/addTyreBrand";
  const addRepairTypeAPI = "http://3.111.214.24:8000/api/addRepairType";
  const addPlyRatingAPI = "http://3.111.214.24:8000/api/addPlyRating";
  const addDriverDetailsAPI = "http://3.111.214.24:8000/api/addDriverDetails";
  const getTyreNumberAPI = "http://3.111.214.24:8000/api/getTyreNumber";
  const tyreRetreadingAPI = "http://3.111.214.24:8000/api/tyreRetreading";
  const tyreReplacementAPI = "http://3.111.214.24:8000/tyreReplacement";
  const getRepairTypesAPI = "http://3.111.214.24:8000/api/getRepairType";
  const addTyreRepairAPI = "http://3.111.214.24:8000/api/addTyreRepair";
  const tyreFittingAPI = "http://3.111.214.24:8000/api/tyreFitting";
  const truckTyresAPI = "http://3.111.214.24:8000/api/truckTyres";
  const tyreBlastAPI = "http://3.111.214.24:8000/api/tyreBlast";
  const tyreDetailsAPI = "http://3.111.214.24:8000/tyreDetails";
  const rtoAPI = "http://3.111.214.24:8000/api/rto";
  const getRtoAPI = "http://3.111.214.24:8000/api/getRto";
  const getTruckDetailsAPI = "http://3.111.214.24:8000/api/getTruckDetails";
  const restockEngineOilAPI = "http://3.111.214.24:8000/api/restockEngineOil";
  const engineOilDistributionAPI =
    "http://3.111.214.24:8000/api/engineOilDistribution";
  const getEngineOilStockAPI = "http://3.111.214.24:8000/api/getEngineOilStock";
  const viewEngineOilAPI = "http://3.111.214.24:8000/api/viewEngineOil";
  const addOrganisationMasterAPI =
    "http://3.111.214.24:8000/api/addOrganisationMaster";
  const addLocationMasterAPI = "http://3.111.214.24:8000/api/addLocationMaster";
  const addTypeOfVehicleAPI = "http://3.111.214.24:8000/api/addTypeOfVehicle";
  const getTypeOfVehicleAPI = "http://3.111.214.24:8000/api/getTypeOfVehicle";
  const addOrganisationAddressAPI =
    "http://3.111.214.24:8000/api/addOrganisationAddress";
  const addContainerTypeAPI = "http://3.111.214.24:8000/api/addContainerType";
  const getContainerTypesAPI = "http://3.111.214.24:8000/api/getContainerTypes";
  const getLocationMastersAPI =
    "http://3.111.214.24:8000/api/getLocationMasters";

  return {
    getTruckNumberAPI,
    getDriversAPI,
    driverAssignmentAPI,
    getVendorAPI,
    getTyreModelAPI,
    getTyreBrandAPI,
    getTyreTypeAPI,
    getTyreSizeAPI,
    getPlyRatingAPI,
    insertNewTyreAPI,
    addVendorAPI,
    addVehicleAPI,
    addTyreTypeAPI,
    addTyreSizeAPI,
    addTyreModelAPI,
    addTyreBrandAPI,
    addRepairTypeAPI,
    addPlyRatingAPI,
    addDriverDetailsAPI,
    getTyreNumberAPI,
    tyreRetreadingAPI,
    tyreReplacementAPI,
    getRepairTypesAPI,
    addTyreRepairAPI,
    tyreFittingAPI,
    truckTyresAPI,
    tyreBlastAPI,
    tyreDetailsAPI,
    rtoAPI,
    getRtoAPI,
    getTruckDetailsAPI,
    restockEngineOilAPI,
    engineOilDistributionAPI,
    getEngineOilStockAPI,
    viewEngineOilAPI,
    addOrganisationMasterAPI,
    addLocationMasterAPI,
    addTypeOfVehicleAPI,
    getTypeOfVehicleAPI,
    addOrganisationAddressAPI,
    addContainerTypeAPI,
    getContainerTypesAPI,
    getLocationMastersAPI,
  };
}
