import React from "react";
import { useFormik } from "formik";
import { TextField } from "@mui/material";
import axios from "axios";
import { validationSchema } from "../../schema/master/tyreBrandSchema";
import { apiRoutes } from "../../utils/apiRoutes";

const TyreBrands = (props) => {
  const { addTyreBrandAPI } = apiRoutes();
  const formik = useFormik({
    initialValues: {
      tyre_brand: "",
      description: "",
    },

    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      console.log(values);
      const res = await axios.post(addTyreBrandAPI, values);
      resetForm();
      alert(res.data.message);
    },
  });

  return (
    <div className="form-container">
      <form onSubmit={formik.handleSubmit} className="register-form">
        <h3>Add a Tyre Brand</h3>
        <TextField
          margin="dense"
          variant="outlined"
          fullWidth
          id="tyre_brand"
          name="tyre_brand"
          label="Tyre Brand"
          value={formik.values.tyre_brand}
          onChange={formik.handleChange}
          error={formik.touched.tyre_brand && Boolean(formik.errors.tyre_brand)}
          helperText={formik.touched.tyre_brand && formik.errors.tyre_brand}
        />

        <TextField
          type="text"
          margin="dense"
          variant="outlined"
          fullWidth
          id="description"
          name="description"
          label="Description"
          value={formik.values.description}
          onChange={formik.handleChange}
          error={
            formik.touched.description && Boolean(formik.errors.description)
          }
          helperText={formik.touched.description && formik.errors.description}
        />

        <button
          type="submit"
          className="submit-form-btn"
          aria-labelledby="submit-btn"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default TyreBrands;
