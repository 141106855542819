import React, { useEffect, useState } from "react";
import axios from "axios";
import { generateLrPdf } from "../../../utils/generateLrPdf";
import Checkbox from "@mui/material/Checkbox";
import DeleteIcon from "@mui/icons-material/Delete";
import { Tooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import Autocomplete from "@mui/material/Autocomplete";
import { TextField } from "@mui/material";
import { apiRoutes } from "../../../utils/apiRoutes";

function Container(props) {
  const [selectedPrNo, setSelectedPrNo] = useState(null);
  const [consignorName, setConsignorName] = useState("");
  const [tableOneData, setTableOneData] = useState([]);
  const [tableTwoData, setTableTwoData] = useState([]);
  const [rowData, setRowData] = useState({});
  const [truck_no, setTruckNo] = useState([]);
  const [truckTypes, setTruckTypes] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [lrData, setLrData] = useState();
  const [organisations, setOrganisations] = useState([]);
  const [address, setAddress] = useState("");
  const [containerTypes, setContainerTypes] = useState([]);
  const { getLocationMastersAPI } = apiRoutes();
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    const getPrData = async () => {
      const res = await axios(
        `http://3.111.214.24:9002/api/getPrData/${props.branch}`
      );
      setTableOneData(res.data);
    };
    getPrData();
  }, [props.branch]);

  useEffect(() => {
    async function fetchData() {
      const res = await axios.get(
        "http://3.111.214.24:8000/api/getOrganisations"
      );
      setOrganisations(res.data);
    }

    async function getContainerTypes() {
      const res = await axios("http://3.111.214.24:8000/api/getContainerTypes");
      setContainerTypes(res.data);
    }

    async function getLocationMasters() {
      const res = await axios(getLocationMastersAPI);
      setLocations(res.data);
    }
    fetchData();
    getContainerTypes();
    getLocationMasters();
  }, []);

  // Function to handle changes in input fields
  const handleInputChange = (index, field, value) => {
    setRowData((prevData) => ({
      ...prevData,
      [index]: {
        ...prevData[index],
        [field]: value,
      },
    }));
  };

  const handleSave = async (index) => {
    const updatedRow = {
      ...tableOneData[index],
      ...rowData[index],
    };

    // Update the branch value in the updatedRow if it's not set
    if (!updatedRow.branch) {
      updatedRow.branch = "THAR DRY PORT";
    }
    if (!updatedRow.container_loading) {
      updatedRow.container_loading = "Ahmedabad";
    }
    if (!updatedRow.container_offloading) {
      updatedRow.container_offloading = "Ahmedabad";
    }
    if (!updatedRow.goods_pickup) {
      updatedRow.goods_pickup = "Ahmedabad";
    }
    if (!updatedRow.goods_delivery) {
      updatedRow.goods_delivery = "Ahmedabad";
    }

    // Update the row data in the local state
    setRowData((prevData) => ({
      ...prevData,
      [index]: {
        ...prevData[index],
        ...rowData[index],
      },
    }));

    // Update the row data in the tableOneData state
    setTableOneData((prevTableOneData) => {
      const updatedTableOneData = [...prevTableOneData];
      updatedTableOneData[index] = {
        ...updatedTableOneData[index],
        ...updatedRow,
      };
      return updatedTableOneData;
    });

    if (!updatedRow.consignor && !updatedRow.consignee) {
      alert("Please enter consignor/consignee name");
      return;
    }
    console.log(updatedRow);
    const res = await axios.post(
      "http://3.111.214.24:9002/api/updatePr",
      updatedRow
    );
    alert(res.data.message);

    const updatedTableOneData = await axios(
      `http://3.111.214.24:9002/api/getPrData/${props.branch}`
    );
    setTableOneData(updatedTableOneData.data);
  };

  const handlePrClick = async (prNo, e) => {
    setSelectedPrNo(prNo ? prNo : e.target.value);
    setTableTwoData([]);
    const res = await axios.post(`http://3.111.214.24:9002/api/getContainers`, {
      pr_no: prNo,
    });

    setTableTwoData(res.data.containers || []);
    const fiteredData = tableOneData.filter((item) => item.pr_no === prNo);
    setConsignorName(fiteredData[0].consignor);
    setLrData(null);
    setLrData(fiteredData[0]);

    const res2 = await axios.post(
      `http://3.111.214.24:8000/api/getOrganisationData`,
      {
        name: fiteredData[0].consignor,
      }
    );

    const defaultBranch = res2.data.branches?.find((branch) => branch?.default);

    if (defaultBranch) {
      // Find the address where default is true within the default branch
      const defaultAddress = defaultBranch.addresses.find(
        (address) => address.default
      );

      if (defaultAddress) {
        setAddress(defaultAddress.address);
      } else {
        console.log("No default address found in the default branch.");
      }
    } else {
      console.log("No default branch found.");
    }
  };

  const handleAddRow = () => {
    setTableOneData([...tableOneData, {}]);
  };

  const handleTypeOfVehicleChange = async (index, field, value) => {
    setTableTwoData((prevData) => {
      const newData = [...prevData];
      newData[index] = {
        ...newData[index],
        [field]: value,
      };
      return newData;
    });
  };

  const handleTruckNoChange = async (index, field, value) => {
    const selectedTruckNo = value;
    setTableTwoData((prevData) => {
      const newData = [...prevData];
      newData[index] = {
        ...newData[index],
        [field]: value,
      };
      return newData;
    });
    const res = await axios(
      `http://3.111.214.24:8000/api/getTruckDetails/${selectedTruckNo}`
    );

    // Access the last object in the drivers array and retrieve the driver name
    const lastDriver = res.data.drivers[res.data.drivers.length - 1];
    const driver_name = lastDriver ? lastDriver.driver_name : ""; // Ensure lastDriver exists

    const res2 = await axios("http://3.111.214.24:8000/api/getDrivers");
    const drivers = res2.data.filter(
      (item) => item.driver_name === driver_name
    );
    if (drivers.length > 0) {
      // Update the driver details for the corresponding row in tableTwoData
      setTableTwoData((prevTableTwoData) => {
        const updatedTableTwoData = [...prevTableTwoData];
        updatedTableTwoData[index] = {
          ...updatedTableTwoData[index],
          driver_name: driver_name,
          driver_phone: drivers[0].driver_phone,
          vehicle_no: selectedTruckNo,
        };
        return updatedTableTwoData;
      });
    } else {
      setTableTwoData((prevTableTwoData) => {
        const updatedTableTwoData = [...prevTableTwoData];
        updatedTableTwoData[index] = {
          ...updatedTableTwoData[index],
          vehicle_no: selectedTruckNo,
        };
        return updatedTableTwoData;
      });
    }
  };

  const handleTruckTypesChange = async (index, field, value) => {
    setTableTwoData((prevData) => {
      const newData = [...prevData];
      newData[index] = {
        ...newData[index],
        [field]: value,
      };
      return newData;
    });
  };

  const handleTableTwoInputChange = (index, field, value) => {
    setTableTwoData((prevData) => {
      const newData = [...prevData];
      newData[index] = {
        ...newData[index],
        [field]: value,
      };
      return newData;
    });
  };

  const checkRegex = (e) => {
    // Allow only digits and certain special keys like backspace and delete
    const allowedKeys = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "Backspace",
      "Delete",
      "ArrowLeft",
      "ArrowRight",
      "Tab",
      "Enter",
    ];
    if (!allowedKeys.includes(e.key)) {
      e.preventDefault();
    }
  };

  const checkAlphabets = (e) => {
    // Allow only alphabets and certain special keys like backspace and delete
    const allowedKeys = [
      "a",
      "b",
      "c",
      "d",
      "e",
      "f",
      "g",
      "h",
      "i",
      "j",
      "k",
      "l",
      "m",
      "n",
      "o",
      "p",
      "q",
      "r",
      "s",
      "t",
      "u",
      "v",
      "w",
      "x",
      "y",
      "z",
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
      "Backspace",
      "Delete",
      "ArrowLeft",
      "ArrowRight",
      "Tab",
      "Enter",
    ];
    if (!allowedKeys.includes(e.key)) {
      e.preventDefault();
    }
  };

  const handleSaveTableTwo = async (index) => {
    const updatedRow = {
      ...tableTwoData[index],
      pr_no: selectedPrNo,
    };

    if (!updatedRow.goods_pickup) {
      updatedRow.goods_pickup = "Ahmedabad";
    }
    if (!updatedRow.goods_delivery) {
      updatedRow.goods_delivery = "Ahmedabad";
    }

    if (
      !updatedRow.hasOwnProperty("container_number") ||
      updatedRow.container_number === ""
    ) {
      alert("Please enter container number");
      return;
    }

    const containerNumber = updatedRow.container_number.toUpperCase();
    const containerNumberRegex = /^[A-Z]{3}[UJZ][A-Z0-9]{6}\d$/i;

    let proceed = true;

    if (!containerNumberRegex.test(containerNumber)) {
      proceed = window.confirm(
        "Container number is not valid. Do you want to continue?"
      );
    } else {
      // Validate check digit
      const checkDigit = calculateCheckDigit(containerNumber.slice(0, 10));
      if (checkDigit !== parseInt(containerNumber[10], 10)) {
        proceed = window.confirm(
          "Invalid container number. Do you want to continue?"
        );
      }
    }

    if (!proceed) return;

    if (containerNumber.trim() !== "") {
      const duplicateContainerNumbers = tableTwoData.reduce((acc, obj) => {
        if (obj.container_number && !acc[obj.container_number]) {
          acc[obj.container_number] = true; // Mark as seen
        } else if (obj.container_number) {
          acc[obj.container_number] = false; // Duplicate found
        }
        return acc;
      }, {});

      // Filter out non-duplicate container numbers
      const duplicates = Object.keys(duplicateContainerNumbers).filter(
        (number) => !duplicateContainerNumbers[number]
      );

      if (duplicates.length > 0) {
        alert("Duplicate container numbers found");
      } else {
        try {
          const res = await axios.post(
            "http://3.111.214.24:9002/api/updateContainer",
            updatedRow
          );
          alert(res.data.message);
          const res2 = await axios.post(
            "http://3.111.214.24:9002/api/getContainers",
            { pr_no: selectedPrNo }
          );
          setTableTwoData(res2.data.containers || []);
          const filteredData = tableOneData.filter(
            (item) => item.pr_no === selectedPrNo
          );
          setLrData(null);
          setLrData(filteredData[0]);
        } catch (error) {
          console.error("Error updating container:", error);
        }
      }
    }
  };

  function equivalentValue(char) {
    const equivalences = {
      A: 10,
      B: 12,
      C: 13,
      D: 14,
      E: 15,
      F: 16,
      G: 17,
      H: 18,
      I: 19,
      J: 20,
      K: 21,
      L: 23,
      M: 24,
      N: 25,
      O: 26,
      P: 27,
      Q: 28,
      R: 29,
      S: 30,
      T: 31,
      U: 32,
      V: 34,
      W: 35,
      X: 36,
      Y: 37,
      Z: 38,
      1: 1,
      2: 2,
      3: 3,
      4: 4,
      5: 5,
      6: 6,
      7: 7,
      8: 8,
      9: 9,
      0: 0,
    };
    return equivalences[char];
  }

  function calculateCheckDigit(containerNumber) {
    if (containerNumber.length !== 10) {
      return null;
    }

    const weightingFactors = [1, 2, 4, 8, 16, 32, 64, 128, 256, 512];
    let total = 0;

    for (let i = 0; i < containerNumber.length; i++) {
      total += equivalentValue(containerNumber[i]) * weightingFactors[i];
    }

    const subTotal = Math.floor(total / 11);
    const checkDigit = total - subTotal * 11;

    return checkDigit;
  }

  function equivalentValue(char) {
    const equivalences = {
      A: 10,
      B: 12,
      C: 13,
      D: 14,
      E: 15,
      F: 16,
      G: 17,
      H: 18,
      I: 19,
      J: 20,
      K: 21,
      L: 23,
      M: 24,
      N: 25,
      O: 26,
      P: 27,
      Q: 28,
      R: 29,
      S: 30,
      T: 31,
      U: 32,
      V: 34,
      W: 35,
      X: 36,
      Y: 37,
      Z: 38,
      1: 1,
      2: 2,
      3: 3,
      4: 4,
      5: 5,
      6: 6,
      7: 7,
      8: 8,
      9: 9,
      0: 0,
    };
    return equivalences[char];
  }

  function calculateCheckDigit(containerNumber) {
    if (containerNumber.length !== 10) {
      return null;
    }

    const weightingFactors = [1, 2, 4, 8, 16, 32, 64, 128, 256, 512];
    let total = 0;

    for (let i = 0; i < containerNumber.length; i++) {
      total += equivalentValue(containerNumber[i]) * weightingFactors[i];
    }

    const subTotal = Math.floor(total / 11);
    const checkDigit = total - subTotal * 11;

    return checkDigit;
  }

  const handleAddTableTwoRow = () => {
    setTableTwoData([...tableTwoData, {}]);
  };

  const handleCheckboxChange = (e, index, _id) => {
    if (e.target.checked) {
      // Add the row data to selectedRows array
      const selectedRow = {
        ...tableTwoData[index],
        description:
          tableOneData.find((item) => item.pr_no === selectedPrNo)
            ?.description || "",
      };
      setSelectedRows([...selectedRows, selectedRow]);
    } else {
      // Remove the row data from selectedRows array
      const updatedSelectedRows = selectedRows.filter((row) => _id !== row._id);
      setSelectedRows(updatedSelectedRows);
    }
  };

  const handleDelete = async (pr_no) => {
    // Show confirmation dialog
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this PR?"
    );

    if (confirmDelete) {
      // If user confirms deletion, proceed with deletion
      const res = await axios.post(`http://3.111.214.24:9002/api/deletePr/`, {
        pr_no,
      });
      alert(res.data.message);

      const updatedTableOneData = await axios(
        "http://3.111.214.24:9002/api/getPrData"
      );
      setTableOneData(updatedTableOneData.data);
    }
  };

  const handleDeleteTr = async (selectedPrNo, tr_no, container_number) => {
    // Show confirmation dialog
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this TR?"
    );

    if (confirmDelete) {
      // If user confirms deletion, proceed with deletion
      const res = await axios.post(`http://3.111.214.24:9002/api/deleteTr`, {
        pr_no: selectedPrNo,
        tr_no,
        container_number,
      });
      alert(res.data.message);

      const res2 = await axios.post(
        `http://3.111.214.24:9002/api/getContainers`,
        {
          pr_no: selectedPrNo,
        }
      );

      setTableTwoData(res2.data.containers || []);
      const fiteredData = tableOneData.filter(
        (item) => item.pr_no === selectedPrNo
      );
      setLrData(null);
      setLrData(fiteredData[0]);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const res = await axios(`http://3.111.214.24:8000/api/getTypeOfVehicle`);
      setTruckTypes(res.data);
    };
    fetchData();
    const fetchTruckNo = async () => {
      const res = await axios(`http://3.111.214.24:8000/api/getTruckNumber`);
      const truckNos = res.data.map((item) => item.truck_no);
      setTruckNo(truckNos);
    };
    fetchTruckNo();
  }, []);

  const validateVehicleNo = (value, index) => {
    const format = /^[A-Za-z]{2}\d{2}[A-Za-z]{2}\d{4}$/;
    if (!format.test(value)) {
      alert("Incorrect Vehicle No Format");
      // Optionally, reset the value if it's invalid
      handleTableTwoInputChange(index, "vehicle_no", "");
    }
  };

  const validatePhoneNo = (value, index) => {
    const phoneRegex = /^[6-9]\d{9}$/;
    if (!phoneRegex.test(value)) {
      alert("Incorrect Phone No Format");
      handleTableTwoInputChange(index, "driver_phone", "");
    }
  };

  return (
    <div>
      <br />
      <div style={{ width: "100%", overflow: "scroll" }}>
        <table className="table-bordered">
          <thead>
            <tr>
              <th></th>
              <th>PR Date</th>
              <th>PR Number</th>
              <th>Branch</th>
              <th>Consignor</th>
              <th>Consignee</th>
              <th>Container Type</th>
              <th>No of Containers</th>
              <th>Gross Weight</th>
              <th>Type of Vehicle Required</th>
              <th>No of Vehicle Required</th>
              <th>Description</th>
              <th>Shipping Line</th>
              <th>Container Loading</th>
              <th>Container Offloading</th>
              <th>DO Validity</th>
              <th>Instructions</th>
              <th>Document No</th>
              <th>Document Date</th>
              <th>Goods Pickup</th>
              <th>Goods Delivery</th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            {tableOneData.map((data, index) => (
              <tr key={index}>
                <td>
                  <DeleteIcon
                    sx={{ color: "#BE3838", cursor: "pointer" }}
                    onClick={() => handleDelete(data.pr_no)}
                  />
                </td>
                <td onClick={(e) => handlePrClick(data.pr_no, e)}>
                  <p>{data.pr_date}</p>
                </td>
                <td onClick={(e) => handlePrClick(data.pr_no, e)}>
                  <p>{data.pr_no}</p>
                </td>
                <td>
                  <select
                    defaultValue={data.branch}
                    onChange={(e) =>
                      handleInputChange(index, "branch", e.target.value)
                    }
                  >
                    <option value="THAR DRY PORT">THAR DRY PORT</option>
                    <option value="ICD Sabarmati, Ahmedabad">
                      ICD Sabarmati, Ahmedabad
                    </option>
                    <option value="HAZIRA">HAZIRA</option>
                    <option value="MUNDRA">MUNDRA</option>
                    <option value="ICD SACHANA">ICD SACHANA</option>
                    <option value="BARODA">BARODA</option>
                  </select>
                </td>
                <td>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Autocomplete
                      disablePortal
                      options={organisations}
                      getOptionLabel={(option) => option}
                      sx={{ width: 300 }}
                      renderInput={(params) => (
                        <TextField size="small" {...params} />
                      )}
                      onChange={(e, value) =>
                        handleInputChange(index, "consignor", value)
                      }
                      defaultValue={data.consignor}
                    />
                    <Tooltip title={data.consignor}>
                      <InfoIcon />
                    </Tooltip>
                  </div>
                </td>
                <td>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Autocomplete
                      disablePortal
                      options={organisations}
                      getOptionLabel={(option) => option}
                      sx={{ width: 300 }}
                      renderInput={(params) => (
                        <TextField size="small" {...params} />
                      )}
                      onChange={(e, value) =>
                        handleInputChange(index, "consignee", value)
                      }
                      defaultValue={data.consignee}
                    />
                    <Tooltip title={data.consignee}>
                      <InfoIcon />
                    </Tooltip>
                  </div>
                </td>
                <td>
                  <select
                    defaultValue={data.container_type}
                    onChange={(e) =>
                      handleInputChange(index, "container_type", e.target.value)
                    }
                  >
                    <option value="">Select</option>
                    {containerTypes.map((container_type, id) => (
                      <option key={id}>{container_type}</option>
                    ))}
                  </select>
                </td>
                <td>
                  <input
                    style={{ width: "90px" }}
                    type="text"
                    defaultValue={data.container_count}
                    onChange={(e) =>
                      handleInputChange(
                        index,
                        "container_count",
                        e.target.value
                      )
                    }
                    onKeyDown={(e) => checkRegex(e)}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    defaultValue={data.gross_weight}
                    onChange={(e) =>
                      handleInputChange(index, "gross_weight", e.target.value)
                    }
                    onKeyDown={(e) => checkRegex(e)}
                  />
                </td>
                <td>
                  <select
                    defaultValue={data.type_of_vehicle}
                    onChange={(e) =>
                      handleInputChange(
                        index,
                        "type_of_vehicle",
                        e.target.value
                      )
                    }
                  >
                    {truckTypes.map((item, id) => (
                      <option key={id} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                </td>
                <td>
                  <input
                    style={{ width: "90px" }}
                    type="text"
                    defaultValue={
                      data.container_count
                        ? data.container_count
                        : data.no_of_vehicle
                    }
                    onChange={(e) =>
                      handleInputChange(index, "no_of_vehicle", e.target.value)
                    }
                    onKeyDown={(e) => checkRegex(e)}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    defaultValue={data.description}
                    onChange={(e) =>
                      handleInputChange(index, "description", e.target.value)
                    }
                  />
                </td>
                <td>
                  <input
                    type="text"
                    defaultValue={data.shipping_line}
                    onChange={(e) =>
                      handleInputChange(
                        index,
                        "shipping_line_airline",
                        e.target.value
                      )
                    }
                  />
                </td>
                <td>
                  <Autocomplete
                    disablePortal
                    options={locations}
                    getOptionLabel={(option) => option}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField size="small" {...params} />
                    )}
                    onChange={(e) =>
                      handleInputChange(
                        index,
                        "container_loading",
                        e.target.value
                      )
                    }
                    defaultValue={data.container_loading}
                  />
                </td>
                <td>
                  <Autocomplete
                    disablePortal
                    options={locations}
                    getOptionLabel={(option) => option}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField size="small" {...params} />
                    )}
                    onChange={(e) =>
                      handleInputChange(
                        index,
                        "container_offloading",
                        e.target.value
                      )
                    }
                    defaultValue={data.container_offloading}
                  />
                </td>
                <td>
                  <input
                    type="date"
                    defaultValue={data.do_validity}
                    onChange={(e) =>
                      handleInputChange(index, "do_validity", e.target.value)
                    }
                  />
                </td>
                <td>
                  <input
                    type="text"
                    defaultValue={data.instructions}
                    onChange={(e) =>
                      handleInputChange(index, "instructions", e.target.value)
                    }
                  />
                </td>
                <td>
                  <input
                    type="text"
                    defaultValue={data.document_no}
                    onChange={(e) =>
                      handleInputChange(index, "document_no", e.target.value)
                    }
                  />
                </td>
                <td>
                  <input
                    type="date"
                    defaultValue={data.document_date}
                    onChange={(e) => {
                      handleInputChange(index, "document_date", e.target.value);
                    }}
                  />
                </td>
                <td>
                  <Autocomplete
                    disablePortal
                    options={locations}
                    getOptionLabel={(option) => option}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField size="small" {...params} />
                    )}
                    onChange={(e) =>
                      handleInputChange(index, "goods_pickup", e.target.value)
                    }
                    defaultValue={data.goods_pickup}
                  />
                </td>
                <td>
                  <Autocomplete
                    disablePortal
                    options={locations}
                    getOptionLabel={(option) => option}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField size="small" {...params} />
                    )}
                    onChange={(e) =>
                      handleInputChange(index, "goods_delivery", e.target.value)
                    }
                    defaultValue={data.consignor}
                  />
                </td>

                <td>
                  <button
                    style={{
                      padding: "5px 10px",
                      border: "none",
                      outline: "none",
                      backgroundColor: "#273041",
                      color: "#fff",
                    }}
                    onClick={() => handleSave(index)}
                  >
                    Save
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <br />
      <button
        style={{
          padding: "5px 10px",
          border: "none",
          outline: "none",
          backgroundColor: "#273041",
          color: "#fff",
        }}
        onClick={handleAddRow}
      >
        Add Row
      </button>
      <br />
      <br />
      <div style={{ width: "100%", overflow: "scroll" }}>
        {selectedPrNo && (
          <>
            <br />
            <h3>
              {selectedPrNo} - {consignorName}
            </h3>
            <table className="table-bordered">
              <thead>
                <tr>
                  <th></th>
                  <th></th>
                  <th>Transport Receipt No</th>
                  <th>Container No</th>
                  <th>Seal No</th>
                  <th>Gross Weight</th>
                  <th>Tare Weight</th>
                  <th>Net Weight</th>
                  <th>Goods Pickup</th>
                  <th>Goods Delivery</th>
                  <th>Own/Hired</th>
                  <th>Type of Vehicle</th>
                  <th>Vehicle Number</th>
                  <th>Driver Name</th>
                  <th>Driver Phone</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {tableTwoData?.map((data, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <Checkbox
                          style={{ padding: 0 }}
                          onChange={(e) =>
                            handleCheckboxChange(e, index, data._id)
                          }
                        />
                      </td>
                      <td>
                        <DeleteIcon
                          sx={{ cursor: "pointer", color: "#BD3838" }}
                          onClick={() =>
                            handleDeleteTr(
                              selectedPrNo,
                              data.tr_no,
                              data.container_number
                            )
                          }
                        />
                      </td>
                      <td>
                        <p>{data.tr_no}</p>
                      </td>
                      <td>
                        <input
                          type="text"
                          value={data.container_number}
                          onChange={(e) =>
                            handleTableTwoInputChange(
                              index,
                              "container_number",
                              e.target.value
                            )
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={data.seal_no}
                          onChange={(e) =>
                            handleTableTwoInputChange(
                              index,
                              "seal_no",
                              e.target.value
                            )
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={data.gross_weight}
                          onChange={(e) =>
                            handleTableTwoInputChange(
                              index,
                              "gross_weight",
                              e.target.value
                            )
                          }
                          onKeyDown={(e) => checkRegex(e)}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={data.tare_weight}
                          onChange={(e) =>
                            handleTableTwoInputChange(
                              index,
                              "tare_weight",
                              e.target.value
                            )
                          }
                          onKeyDown={(e) => checkRegex(e)}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={data.net_weight}
                          onChange={(e) =>
                            handleTableTwoInputChange(
                              index,
                              "net_weight",
                              e.target.value
                            )
                          }
                          onKeyDown={(e) => checkRegex(e)}
                        />
                      </td>
                      <td>
                        <Autocomplete
                          disablePortal
                          options={locations}
                          getOptionLabel={(option) => option}
                          sx={{ width: 300 }}
                          renderInput={(params) => (
                            <TextField size="small" {...params} />
                          )}
                          onChange={(e) =>
                            handleTableTwoInputChange(
                              index,
                              "goods_pickup",
                              e.target.value
                            )
                          }
                          defaultValue={data.goods_pickup}
                        />
                      </td>
                      <td>
                        <Autocomplete
                          disablePortal
                          options={locations}
                          getOptionLabel={(option) => option}
                          sx={{ width: 300 }}
                          renderInput={(params) => (
                            <TextField size="small" {...params} />
                          )}
                          onChange={(e) =>
                            handleTableTwoInputChange(
                              index,
                              "goods_delivery",
                              e.target.value
                            )
                          }
                          defaultValue={data.goods_delivery}
                        />
                      </td>
                      <td>
                        <select
                          value={data.own_hired}
                          onChange={(e) =>
                            handleTypeOfVehicleChange(
                              index,
                              "own_hired",
                              e.target.value
                            )
                          }
                        >
                          <option value="hired">Hired</option>
                          <option value="own">Own</option>
                        </select>
                      </td>
                      <td>
                        <select
                          value={data.type_of_vehicle}
                          onChange={(e) =>
                            handleTruckTypesChange(
                              index,
                              "type_of_vehicle",
                              e.target.value
                            )
                          }
                        >
                          {truckTypes.map((item, id) => (
                            <option key={id} value={item}>
                              {item}
                            </option>
                          ))}
                        </select>
                      </td>
                      <td>
                        {data.own_hired === "own" ? (
                          <select
                            value={data.vehicle_no}
                            onChange={(e) =>
                              handleTruckNoChange(
                                index,
                                "vehicle_no",
                                e.target.value
                              )
                            }
                          >
                            <option value="">Select</option>
                            {truck_no.map((item, id) => (
                              <option key={id} value={item}>
                                {item}
                              </option>
                            ))}
                          </select>
                        ) : (
                          <input
                            type="text"
                            value={data.vehicle_no}
                            onChange={(e) =>
                              handleTableTwoInputChange(
                                index,
                                "vehicle_no",
                                e.target.value
                              )
                            }
                            onBlur={(e) =>
                              validateVehicleNo(e.target.value, index)
                            }
                          />
                        )}
                      </td>

                      <td>
                        <input
                          type="text"
                          value={data.driver_name}
                          onChange={(e) =>
                            handleTableTwoInputChange(
                              index,
                              "driver_name",
                              e.target.value
                            )
                          }
                          onKeyDown={(e) => checkAlphabets(e)}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={data.driver_phone}
                          onChange={(e) =>
                            handleTableTwoInputChange(
                              index,
                              "driver_phone",
                              e.target.value
                            )
                          }
                          onKeyDown={(e) => checkRegex(e)}
                          onBlur={(e) => validatePhoneNo(e.target.value, index)}
                        />
                      </td>
                      <td>
                        <button
                          style={{
                            padding: "5px 10px",
                            border: "none",
                            outline: "none",
                            backgroundColor: "#273041",
                            color: "#fff",
                          }}
                          onClick={() => handleSaveTableTwo(index)}
                        >
                          Save
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <br />
            <button
              style={{
                padding: "5px 10px",
                border: "none",
                outline: "none",
                backgroundColor: "#273041",
                color: "#fff",
              }}
              onClick={handleAddTableTwoRow}
            >
              Add Row
            </button>

            <button
              style={{
                padding: "5px 10px",
                border: "none",
                outline: "none",
                backgroundColor: "#273041",
                color: "#fff",
                marginLeft: "10px",
              }}
              onClick={() => generateLrPdf(selectedRows, lrData, address)}
            >
              Generate PDF
            </button>
            <br />
            <br />
          </>
        )}
      </div>
    </div>
  );
}

export default Container;
