import * as yup from "yup";

export const validationSchema = yup.object({
  fitness_document: yup
    .string("Enter Fitness Document")
    .required("Please enter your Fitness Document"),
  fitness_document_expiry_date: yup
    .string("Enter Fitness Document Expiry Date")
    .required("Please enter your Fitness Document Expiry Date"),
  inspection_due_date: yup
    .string("Enter Inspection Due Date")
    .required("Please enter your Inspection Due Date"),
  mv_tax: yup.string("Enter MV Tax").required("Please enter your MV Tax"),
  mv_tax_date: yup
    .string("Enter MV Tax Date")
    .required("Please enter your MV Tax Date"),
  insurance_expiry_date: yup
    .string("Enter Insurance Expiry Date")
    .required("Please enter your Insurance Expiry Date"),
  puc_expiry_date: yup
    .string("Enter PUC Expiry Date")
    .required("Please enter your PUC Expiry Date"),
  goods_permit_no: yup
    .string("Enter Goods Permit No")
    .required("Please enter your Goods Permit No"),
  goods_permit_validity_date: yup
    .string("Enter Goods Permit Validity Date")
    .required("Please enter your Goods Permit Validity Date"),
  national_permit_no: yup
    .string("Enter National Permit No")
    .required("Please enter your National Permit No"),
  national_permit_validity_date: yup
    .string("Enter National Permit Validity Date")
    .required("Please enter your National Permit Validity Date"),
  hp: yup.string("Enter HP").required("Please enter your HP"),
  hp_financer_name: yup
    .string("Enter HP Financer Name")
    .required("Please enter your HP Financer Name"),
  number_plate: yup
    .string("Enter Number Plate")
    .required("Please enter your Number Plate"),
  supd: yup.string("Enter SUPD").required("Please enter your SUPD"),

  fitness_document_photo: yup
    .string("Upload Fitness Document")
    .required("Fitness Document is required"),
  mv_tax_photo: yup.string("Upload MV Tax").required("MV Tax is required"),
  insurance_photo: yup
    .string("Upload Insurance")
    .required("Insurance is required"),
  puc_photo: yup.string("Upload PUC").required("PUC is required"),
  goods_permit_photo: yup
    .string("Upload Goods Permit")
    .required("Goods Permit is required"),
  national_permit_photo: yup
    .string("Upload National Permit")
    .required("National Permit is required"),
  rc_front_photo: yup
    .string("Upload RC Front")
    .required("RC Front is required"),
  rc_rear_photo: yup.string("Upload RC Rear").required("RC Rear is required"),
});
