import React from "react";
import { useFormik } from "formik";
import { TextField } from "@mui/material";
import axios from "axios";
import { validationSchema } from "../../schema/master/containerTypeSchema.mjs";
import { apiRoutes } from "../../utils/apiRoutes";

const PlyRatings = (props) => {
  const { addContainerTypeAPI } = apiRoutes();
  const formik = useFormik({
    initialValues: {
      container_type: "",
    },

    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      console.log(values);
      const res = await axios.post(addContainerTypeAPI, values);
      resetForm();
      alert(res.data.message);
    },
  });

  return (
    <div className="form-container">
      <form onSubmit={formik.handleSubmit} className="register-form">
        <h3>Add Container Type</h3>
        <TextField
          margin="dense"
          variant="outlined"
          fullWidth
          id="container_type"
          name="container_type"
          label="Container Type"
          value={formik.values.container_type}
          onChange={formik.handleChange}
          error={
            formik.touched.container_type &&
            Boolean(formik.errors.container_type)
          }
          helperText={
            formik.touched.container_type && formik.errors.container_type
          }
        />

        <button
          type="submit"
          className="submit-form-btn"
          aria-labelledby="submit-btn"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default PlyRatings;
