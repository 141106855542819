import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";
import AvTimerIcon from "@mui/icons-material/AvTimer";
import BuildIcon from "@mui/icons-material/Build";
import VerifiedIcon from "@mui/icons-material/Verified";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import ArticleIcon from "@mui/icons-material/Article";
import HandymanIcon from "@mui/icons-material/Handyman";

export const sidebarData = [
  {
    id: 0,
    icon: <SpaceDashboardIcon />,
    name: "Dashboard",
    url: "dashboard",
  },
  {
    id: 13,
    icon: <AvTimerIcon />,
    name: "LR Report",
    url: "lr-report",
  },
  { id: 1, icon: <ArticleIcon />, name: "Master", url: "master" },
  { id: 2, icon: <VerifiedIcon />, name: "New Tyre", url: "new-tyre" },
  { id: 3, icon: <BuildIcon />, name: "Tyre Fitting", url: "tyre-fitting" },
  // {
  //   id: 4,
  //   icon: <BuildIcon />,
  //   name: "Tyre Replacement",
  //   url: "tyre-replacement",
  // },
  {
    id: 4,
    icon: <RemoveCircleIcon />,
    name: "Tyre Blast",
    url: "tyre-blast",
  },
  { id: 5, icon: <HandymanIcon />, name: "Tyre Repairs", url: "tyre-repairs" },
  {
    id: 6,
    icon: <HandymanIcon />,
    name: "Tyre Retreading",
    url: "tyre-retreading",
  },
  {
    id: 7,
    icon: <HandymanIcon />,
    name: "Driver Assignment",
    url: "driver-assignment",
  },
  {
    id: 8,
    icon: <AvTimerIcon />,
    name: "Tyre Details",
    url: "tyre-details",
  },
  {
    id: 9,
    icon: <AvTimerIcon />,
    name: "Truck Details",
    url: "truck-details",
  },
  {
    id: 10,
    icon: <AvTimerIcon />,
    name: "Upload RTO Details",
    url: "upload-rto",
  },
  {
    id: 11,
    icon: <AvTimerIcon />,
    name: "RTO",
    url: "rto",
  },
  {
    id: 12,
    icon: <AvTimerIcon />,
    name: "Engine Oil",
    url: "engine-oil",
  },
  {
    id: 14,
    icon: <AvTimerIcon />,
    name: "Feedback",
    url: "http://alvision-feedback.s3-website.ap-south-1.amazonaws.com/",
  },
];
