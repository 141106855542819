import React from "react";
import ReactApexChart from "react-apexcharts";
import useBrandChart from "../../customHooks/useBrandChart";

function IdleTimeCauses() {
  const brandChart = useBrandChart();

  return (
    <div className="chart column-chart">
      <h5>Tyre Brand-wise life</h5>

      <ReactApexChart
        options={brandChart.options}
        series={brandChart.series}
        type="donut"
      />
    </div>
  );
}

export default IdleTimeCauses;
