import React, { useEffect, useState } from "react";
import axios from "axios";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import LaptopMacIcon from "@mui/icons-material/LaptopMac";
import TextField from "@mui/material/TextField";
import { Container, Row, Col } from "react-bootstrap";
import { apiRoutes } from "../utils/apiRoutes";

function TruckDetails() {
  const [truckNo, setTruckNo] = useState("");
  const [data, setData] = useState();
  console.log(data);
  const { getTruckDetailsAPI } = apiRoutes();

  useEffect(() => {
    async function getData() {
      const res = await axios(`${getTruckDetailsAPI}/${truckNo}`);
      setData(res.data);
    }

    getData();
  }, [truckNo]);

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h3 style={{ marginBottom: "20px" }}>Truck Details</h3>
        <TextField
          id="outlined-basic"
          label="Enter a Truck Number"
          variant="outlined"
          onChange={(e) => setTruckNo(e.target.value)}
        />
      </div>

      <Container>
        <Row>
          {data && (
            <>
              {data.tyres.map((tyre) => {
                return (
                  <Col lg={3} xs={12} key={tyre._id}>
                    <p>
                      <strong>Tyre Number:&nbsp;</strong>
                      {tyre.tyre_no}
                    </p>
                    <p>
                      <strong>Location:&nbsp;</strong>
                      {tyre.location}
                    </p>
                    <p>
                      <strong>Fitting Date:&nbsp;</strong>
                      {tyre.fitting_date}
                    </p>
                    <p>
                      <strong>Fitting Date Odometer:&nbsp;</strong>
                      {tyre.fitting_date_odometer}
                    </p>
                  </Col>
                );
              })}
            </>
          )}
        </Row>
      </Container>
    </div>
  );
}

export default TruckDetails;
