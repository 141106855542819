import React from "react";
import { TextField } from "@mui/material";
import { useFormik } from "formik";
import axios from "axios";
import { apiRoutes } from "../utils/apiRoutes";

const fields = [
  { name: "tyre_no", label: "Tyre No" },
  { name: "truck_no_1", label: "Truck 1 No" },
  { name: "truck_no_2", label: "Truck 2 No" },
  { name: "odometer_1", label: "Truck 1 Odometer" },
  { name: "odometer_2", label: "Truck 2 Odometer" },
];

function TyreReplacement() {
  const { tyreReplacementAPI } = apiRoutes();
  const formik = useFormik({
    initialValues: {
      tyre_no: "",
      truck_no_1: "",
      truck_no_2: "",
      odometer_1: "",
      odometer_2: "",
    },

    onSubmit: async (values, { resetForm }) => {
      console.log(values);
      const res = await axios.post(tyreReplacementAPI, values);
      resetForm();
      alert(res.data.message);
    },
  });

  return (
    <div>
      <div className="form">
        <h3>Enter tyre replacement details</h3>
        <form onSubmit={formik.handleSubmit}>
          {fields.map((field) => (
            <TextField
              key={field.name}
              size="small"
              margin="dense"
              variant="outlined"
              fullWidth
              id={field.name}
              name={field.name}
              label={field.label}
              value={formik.values[field.name]}
              onChange={formik.handleChange}
              error={
                formik.touched[field.name] && Boolean(formik.errors[field.name])
              }
              helperText={
                formik.touched[field.name] && formik.errors[field.name]
              }
              className="login-input"
            />
          ))}

          <button className="submit-btn" type="submit">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
}

export default TyreReplacement;
