import React, { useState } from "react";
import { TextField } from "@mui/material";
import { MenuItem } from "@mui/material";
import EngineOilRestock from "./EngineOilRestock";
import EngineOilDistribution from "./EngineOilDistribution";
import EnigneOilTable from "./EnigneOilTable";

const masterList = [
  "Engine Oil Restock",
  "Enigne Oil Distribution",
  "View Engine Oil Distribution",
];

function EngineOilDashboard() {
  const [masterType, setMasterType] = useState("Enigne Oil Distribution");

  const handleMasterChange = (e) => {
    setMasterType(e.target.value);
  };

  const masterComponent = () => {
    switch (masterType) {
      case "Engine Oil Restock":
        return <EngineOilRestock />;
      case "Enigne Oil Distribution":
        return <EngineOilDistribution />;
      case "View Engine Oil Distribution":
        return <EnigneOilTable />;
      default:
        return null;
    }
  };

  return (
    <div>
      <TextField
        select
        size="large"
        margin="normal"
        variant="outlined"
        label="Select Master"
        value={masterType}
        onChange={handleMasterChange}
        sx={{ width: "300px" }}
      >
        {masterList.map((masterType) => {
          return (
            <MenuItem key={masterType} value={masterType}>
              {masterType}
            </MenuItem>
          );
        })}
      </TextField>
      <br />
      <br />
      <div style={{ height: "80vh" }}>{masterComponent()}</div>
    </div>
  );
}

export default EngineOilDashboard;
