import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { MenuItem, TextField } from "@mui/material";
import { validationSchema } from "../schema/rtoSchema";
import axios from "axios";
import { apiRoutes } from "../utils/apiRoutes";
import Autocomplete from "@mui/material/Autocomplete";

function UploadRTO() {
  const formattedDate = new Date().toISOString().split("T")[0];
  const [truckNo, setTruckNo] = useState([]);
  const { rtoAPI, getTruckNumberAPI } = apiRoutes();

  useEffect(() => {
    async function getTruckNumber() {
      const res = await axios.get(getTruckNumberAPI);
      setTruckNo(res.data.map((item) => item.truck_no));
    }

    getTruckNumber();
  }, []);

  const formik = useFormik({
    initialValues: {
      truck_no: "",
      fitness_document: "",
      fitness_document_expiry_date: formattedDate,
      inspection_due_date: formattedDate,
      mv_tax: "",
      mv_tax_date: formattedDate,
      insurance_expiry_date: formattedDate,
      puc_expiry_date: formattedDate,
      goods_permit_no: "",
      goods_permit_validity_date: formattedDate,
      national_permit_no: "",
      national_permit_validity_date: formattedDate,
      hp: "",
      hp_financer_name: "",
      number_plate: "",
      supd: "",
      fitness_document_photo: "",
      mv_tax_photo: "",
      insurance_photo: "",
      puc_photo: "",
      goods_permit_photo: "",
      national_permit_photo: "",
      rc_front_photo: "",
      rc_rear_photo: "",
    },
    // validationSchema: validationSchema,
    onSubmit: async (values) => {
      const res = await axios.post(rtoAPI, values);
      alert(res.data.message);
    },
  });

  const handleTruckNoChange = (event, value) => {
    console.log(value);
    formik.setFieldValue("truck_no", value);
  };

  const handleFitnessDocumentPhotoUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      // Set the formik values with the base64 string
      formik.setFieldValue("fitness_document_photo", reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleMvTaxPhotoUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      // Set the formik values with the base64 string
      formik.setFieldValue("mv_tax_photo", reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleInsurancePhotoUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      // Set the formik values with the base64 string
      formik.setFieldValue("insurance_photo", reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handlePucPhotoUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      // Set the formik values with the base64 string
      formik.setFieldValue("puc_photo", reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleGoodsPermitPhotoUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      // Set the formik values with the base64 string
      formik.setFieldValue("goods_permit_photo", reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleNationalPermitPhotoUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      // Set the formik values with the base64 string
      formik.setFieldValue("national_permit_photo", reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleRcFrontPhotoUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      // Set the formik values with the base64 string
      formik.setFieldValue("rc_front_photo", reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleRcRearPhotoUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      // Set the formik values with the base64 string
      formik.setFieldValue("rc_rear_photo", reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="form-container">
      <form onSubmit={formik.handleSubmit} className="register-form">
        <Autocomplete
          disablePortal
          options={Array.isArray(truckNo) ? truckNo : []}
          getOptionLabel={(option) => option}
          width="100%"
          renderInput={(params) => (
            <TextField
              {...params}
              variant="filled"
              label="Select truck number"
              error={
                formik.touched.blast_truck_no &&
                Boolean(formik.errors.blast_truck_no)
              }
              helperText={
                formik.touched.blast_truck_no && formik.errors.blast_truck_no
              }
            />
          )}
          id="truck_no"
          name="truck_no"
          onChange={handleTruckNoChange}
          value={formik.values.truck_no}
          style={{ marginBottom: "15px" }}
        />

        <TextField
          size="small"
          margin="dense"
          variant="filled"
          fullWidth
          id="fitness_document"
          name="fitness_document"
          label="Fitness Document"
          value={formik.values.fitness_document}
          onChange={formik.handleChange}
          error={
            formik.touched.fitness_document &&
            Boolean(formik.errors.fitness_document)
          }
          helperText={
            formik.touched.fitness_document && formik.errors.fitness_document
          }
        />

        <TextField
          size="small"
          margin="dense"
          variant="filled"
          fullWidth
          type="date"
          id="fitness_document_expiry_date"
          name="fitness_document_expiry_date"
          label="Fitness Document Expiry Date"
          value={formik.values.fitness_document_expiry_date}
          onChange={formik.handleChange}
          error={
            formik.touched.fitness_document_expiry_date &&
            Boolean(formik.errors.fitness_document_expiry_date)
          }
          helperText={
            formik.touched.fitness_document_expiry_date &&
            formik.errors.fitness_document_expiry_date
          }
        />

        <TextField
          size="small"
          margin="dense"
          variant="filled"
          fullWidth
          type="date"
          id="inspection_due_date"
          name="inspection_due_date"
          label="Inspection Due Date"
          value={formik.values.inspection_due_date}
          onChange={formik.handleChange}
          error={
            formik.touched.inspection_due_date &&
            Boolean(formik.errors.inspection_due_date)
          }
          helperText={
            formik.touched.inspection_due_date &&
            formik.errors.inspection_due_date
          }
        />

        <TextField
          size="small"
          margin="dense"
          variant="filled"
          fullWidth
          id="mv_tax"
          name="mv_tax"
          label="MV Tax"
          value={formik.values.mv_tax}
          onChange={formik.handleChange}
          error={formik.touched.mv_tax && Boolean(formik.errors.mv_tax)}
          helperText={formik.touched.mv_tax && formik.errors.mv_tax}
        />

        <TextField
          size="small"
          margin="dense"
          variant="filled"
          fullWidth
          type="date"
          id="mv_tax_date"
          name="mv_tax_date"
          label="MV Tax Date"
          value={formik.values.mv_tax_date}
          onChange={formik.handleChange}
          error={
            formik.touched.mv_tax_date && Boolean(formik.errors.mv_tax_date)
          }
          helperText={formik.touched.mv_tax_date && formik.errors.mv_tax_date}
        />

        <TextField
          size="small"
          margin="dense"
          variant="filled"
          fullWidth
          type="date"
          id="insurance_expiry_date"
          name="insurance_expiry_date"
          label="Insurance Expiry Date"
          value={formik.values.insurance_expiry_date}
          onChange={formik.handleChange}
          error={
            formik.touched.insurance_expiry_date &&
            Boolean(formik.errors.insurance_expiry_date)
          }
          helperText={
            formik.touched.insurance_expiry_date &&
            formik.errors.insurance_expiry_date
          }
        />

        <TextField
          size="small"
          margin="dense"
          variant="filled"
          fullWidth
          type="date"
          id="puc_expiry_date"
          name="puc_expiry_date"
          label="PUC Expiry Date"
          value={formik.values.puc_expiry_date}
          onChange={formik.handleChange}
          error={
            formik.touched.puc_expiry_date &&
            Boolean(formik.errors.puc_expiry_date)
          }
          helperText={
            formik.touched.puc_expiry_date && formik.errors.puc_expiry_date
          }
        />

        <TextField
          size="small"
          margin="dense"
          variant="filled"
          fullWidth
          id="goods_permit_no"
          name="goods_permit_no"
          label="Goods Permit No"
          value={formik.values.goods_permit_no}
          onChange={formik.handleChange}
          error={
            formik.touched.goods_permit_no &&
            Boolean(formik.errors.goods_permit_no)
          }
          helperText={
            formik.touched.goods_permit_no && formik.errors.goods_permit_no
          }
        />

        <TextField
          size="small"
          margin="dense"
          variant="filled"
          fullWidth
          type="date"
          id="goods_permit_validity_date"
          name="goods_permit_validity_date"
          label="Goods Permit Validity Date"
          value={formik.values.goods_permit_validity_date}
          onChange={formik.handleChange}
          error={
            formik.touched.goods_permit_validity_date &&
            Boolean(formik.errors.goods_permit_validity_date)
          }
          helperText={
            formik.touched.goods_permit_validity_date &&
            formik.errors.goods_permit_validity_date
          }
        />

        <TextField
          size="small"
          margin="dense"
          variant="filled"
          fullWidth
          id="national_permit_no"
          name="national_permit_no"
          label="National Permit No"
          value={formik.values.national_permit_no}
          onChange={formik.handleChange}
          error={
            formik.touched.national_permit_no &&
            Boolean(formik.errors.national_permit_no)
          }
          helperText={
            formik.touched.national_permit_no &&
            formik.errors.national_permit_no
          }
        />

        <TextField
          size="small"
          margin="dense"
          variant="filled"
          fullWidth
          type="date"
          id="national_permit_validity_date"
          name="national_permit_validity_date"
          label="National Permit Validity Date"
          value={formik.values.national_permit_validity_date}
          onChange={formik.handleChange}
          error={
            formik.touched.national_permit_validity_date &&
            Boolean(formik.errors.national_permit_validity_date)
          }
          helperText={
            formik.touched.national_permit_validity_date &&
            formik.errors.national_permit_validity_date
          }
        />

        <TextField
          size="small"
          margin="dense"
          variant="filled"
          fullWidth
          select
          id="hp"
          name="hp"
          label="HP"
          value={formik.values.hp}
          onChange={formik.handleChange}
          error={formik.touched.hp && Boolean(formik.errors.hp)}
          helperText={formik.touched.hp && formik.errors.hp}
        >
          <MenuItem value="">Select</MenuItem>
          <MenuItem value="Yes">Yes</MenuItem>
          <MenuItem value="No">No</MenuItem>
        </TextField>

        <TextField
          size="small"
          margin="dense"
          variant="filled"
          fullWidth
          id="hp_financer_name"
          name="hp_financer_name"
          label="HP Financer Name"
          value={formik.values.hp_financer_name}
          onChange={formik.handleChange}
          error={
            formik.touched.hp_financer_name &&
            Boolean(formik.errors.hp_financer_name)
          }
          helperText={
            formik.touched.hp_financer_name && formik.errors.hp_financer_name
          }
        />

        <TextField
          size="small"
          margin="dense"
          variant="filled"
          fullWidth
          select
          id="number_plate"
          name="number_plate"
          label="Number Plate"
          value={formik.values.number_plate}
          onChange={formik.handleChange}
          error={
            formik.touched.number_plate && Boolean(formik.errors.number_plate)
          }
          helperText={formik.touched.number_plate && formik.errors.number_plate}
        >
          <MenuItem value="">Select</MenuItem>
          <MenuItem value="Yes">OK</MenuItem>
        </TextField>

        <TextField
          size="small"
          margin="dense"
          variant="filled"
          fullWidth
          id="supd"
          name="supd"
          label="SUPD"
          value={formik.values.supd}
          onChange={formik.handleChange}
          error={formik.touched.supd && Boolean(formik.errors.supd)}
          helperText={formik.touched.supd && formik.errors.supd}
        />
        <br />
        <br />

        <label htmlFor="fitness_doc">Fitenss Document&nbsp;</label>
        <input
          type="file"
          accept="image/*"
          name="fitness_doc"
          onChange={handleFitnessDocumentPhotoUpload}
        />
        <br />
        <br />

        <label htmlFor="mv_tax">MV Tax&nbsp;</label>
        <input
          type="file"
          accept="image/*"
          name="mv_tax"
          onChange={handleMvTaxPhotoUpload}
        />
        <br />
        <br />

        <label htmlFor="insurance">Insurance&nbsp;</label>
        <input
          type="file"
          accept="image/*"
          name="insurance"
          onChange={handleInsurancePhotoUpload}
        />
        <br />
        <br />

        <label htmlFor="puc">PUC&nbsp;</label>
        <input
          type="file"
          accept="image/*"
          name="puc"
          onChange={handlePucPhotoUpload}
        />
        <br />
        <br />

        <label htmlFor="goods_permit_no">Goods Permit No&nbsp;</label>
        <input
          type="file"
          accept="image/*"
          name="goods_permit_no"
          onChange={handleGoodsPermitPhotoUpload}
        />
        <br />
        <br />

        <label htmlFor="national_permit_no">National Permit No&nbsp;</label>
        <input
          type="file"
          accept="image/*"
          name="national_permit_no"
          onChange={handleNationalPermitPhotoUpload}
        />
        <br />
        <br />

        <label htmlFor="rc_front">RC Front&nbsp;</label>
        <input
          type="file"
          accept="image/*"
          name="rc_front"
          onChange={handleRcFrontPhotoUpload}
        />
        <br />
        <br />

        <label htmlFor="rc_rear">RC Rear&nbsp;</label>
        <input
          type="file"
          accept="image/*"
          name="rc_rear"
          onChange={handleRcRearPhotoUpload}
        />
        <br />
        <br />

        <button className="submit-form-btn" type="submit">
          Submit
        </button>
      </form>
    </div>
  );
}

export default UploadRTO;
