import React, { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import { useFormik } from "formik";
import axios from "axios";
import Autocomplete from "@mui/material/Autocomplete";
import AWS from "aws-sdk";
import { apiRoutes } from "../utils/apiRoutes";

function TyreBlast() {
  const [tyreNo, setTyreNo] = useState([]);
  const [truckNo, setTruckNo] = useState([]);
  const { getTyreNumberAPI, getTruckNumberAPI, tyreBlastAPI } = apiRoutes();

  useEffect(() => {
    async function getTyreNumber() {
      const res = await axios.get(getTyreNumberAPI);
      setTyreNo(res.data);
    }
    async function getTruckNumber() {
      const res = await axios.get(getTruckNumberAPI);
      setTruckNo(res.data);
    }

    getTyreNumber();
    getTruckNumber();
  }, []);

  const formik = useFormik({
    initialValues: {
      tyre_no: "",
      blast_truck_no: "",
      blast_date: "",
      blast_driver: "",
      blast_odometer: "",
      blast_remarks: "",
      blast_images: [],
    },

    onSubmit: async (values, { resetForm }) => {
      const res = await axios.post(tyreBlastAPI, values);
      resetForm();
      alert(res.data.message);
    },
  });

  const handleTyreBlastImages = async (files) => {
    try {
      const s3 = new AWS.S3({
        accessKeyId: "AKIAVRUVQC2QLL7YLPUJ",
        secretAccessKey: "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",
        region: "ap-south-1",
      });

      const locations = [];

      // Upload images to S3 and get their locations
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const params = {
          Bucket: "alvision-srcc-images",
          Key: `tyre-blast-images/${file.name}`,
          Body: file,
        };
        const response = await s3.upload(params).promise();
        locations.push(response.Location);
      }

      // Update the Formik field 'blast_images' with the uploaded image locations
      formik.setFieldValue("blast_images", locations);
    } catch (err) {
      console.log(err);
    }
  };

  const handleTyreNoChange = (event, value) => {
    formik.setFieldValue("tyre_no", value);
  };

  const handleTruckNoChange = (event, value) => {
    formik.setFieldValue("blast_truck_no", value);
  };

  return (
    <div>
      <div className="form">
        <h3>Enter tyre blast details</h3>
        {tyreNo.length !== 0 && truckNo.length !== 0 && (
          <form onSubmit={formik.handleSubmit}>
            <Autocomplete
              disablePortal
              options={Array.isArray(tyreNo) ? tyreNo : []}
              getOptionLabel={(option) => option}
              width="100%"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select tyre number"
                  error={
                    formik.touched.tyre_no && Boolean(formik.errors.tyre_no)
                  }
                  helperText={formik.touched.tyre_no && formik.errors.tyre_no}
                />
              )}
              id="tyre_no"
              name="tyre_no"
              onChange={handleTyreNoChange}
              value={formik.values.tyre_no}
              style={{ marginBottom: "15px" }}
            />

            <Autocomplete
              disablePortal
              options={Array.isArray(truckNo) ? truckNo : []}
              getOptionLabel={(option) => option}
              width="100%"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select truck number"
                  error={
                    formik.touched.blast_truck_no &&
                    Boolean(formik.errors.blast_truck_no)
                  }
                  helperText={
                    formik.touched.blast_truck_no &&
                    formik.errors.blast_truck_no
                  }
                />
              )}
              id="truck_no"
              name="truck_no"
              onChange={handleTruckNoChange}
              value={formik.values.truck_no}
              style={{ marginBottom: "15px" }}
            />

            <TextField
              type="date"
              size="small"
              margin="dense"
              variant="outlined"
              fullWidth
              id="blast_date"
              name="blast_date"
              value={formik.values.blast_date}
              onChange={formik.handleChange}
              error={
                formik.touched.blast_date && Boolean(formik.errors.blast_date)
              }
              helperText={formik.touched.blast_date && formik.errors.blast_date}
              className="login-input"
            />

            <TextField
              type="text"
              size="small"
              margin="dense"
              variant="outlined"
              fullWidth
              id="blast_driver"
              name="blast_driver"
              label="Driver"
              value={formik.values.blast_driver}
              onChange={formik.handleChange}
              error={
                formik.touched.blast_driver &&
                Boolean(formik.errors.blast_driver)
              }
              helperText={
                formik.touched.blast_driver && formik.errors.blast_driver
              }
              className="login-input"
            />

            <TextField
              type="text"
              size="small"
              margin="dense"
              variant="outlined"
              fullWidth
              id="blast_odometer"
              name="blast_odometer"
              label="Odometer"
              value={formik.values.blast_odometer}
              onChange={formik.handleChange}
              error={
                formik.touched.blast_odometer &&
                Boolean(formik.errors.blast_odometer)
              }
              helperText={
                formik.touched.blast_odometer && formik.errors.blast_odometer
              }
              className="login-input"
            />

            <TextField
              type="text"
              size="small"
              margin="dense"
              variant="outlined"
              fullWidth
              id="blast_remarks"
              name="blast_remarks"
              label="Remarks"
              value={formik.values.blast_remarks}
              onChange={formik.handleChange}
              error={
                formik.touched.blast_remarks &&
                Boolean(formik.errors.blast_remarks)
              }
              helperText={
                formik.touched.blast_remarks && formik.errors.blast_remarks
              }
              className="login-input"
            />

            <label htmlFor="tyreBlast" className="uploadBtn-secondary">
              Upload tyre blast images
            </label>
            <input
              type="file"
              multiple
              id="tyreBlast"
              onChange={(e) => handleTyreBlastImages(e.target.files)}
              style={{ display: "none" }}
            />
            <br />
            {formik.values.blast_images.map((image, index) => {
              console.log(image);
              return (
                <div key={index}>
                  <a href={image}>{image}</a>
                  <br />
                </div>
              );
            })}

            <br />

            <button className="submit-btn" type="submit">
              Submit
            </button>
          </form>
        )}
      </div>
    </div>
  );
}

export default TyreBlast;
