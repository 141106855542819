import React from "react";
import { useFormik } from "formik";
import { TextField } from "@mui/material";
import axios from "axios";
import { validationSchema } from "../../schema/master/typeOfVehicleSchema";
import { apiRoutes } from "../../utils/apiRoutes";

const TypeOfVehicle = (props) => {
  const { addTypeOfVehicleAPI } = apiRoutes();
  const formik = useFormik({
    initialValues: {
      type_of_vehicle: "",
    },

    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      console.log(values);
      const res = await axios.post(addTypeOfVehicleAPI, values);
      console.log(res.data);
      resetForm();
      alert(res.data.message);
    },
  });

  return (
    <div className="form-container">
      <form onSubmit={formik.handleSubmit} className="register-form">
        <h3>Add Type of Vehicle</h3>
        <TextField
          margin="dense"
          variant="outlined"
          fullWidth
          id="type_of_vehicle"
          name="type_of_vehicle"
          label="Type of Vehicle"
          value={formik.values.type_of_vehicle}
          onChange={formik.handleChange}
          error={
            formik.touched.type_of_vehicle &&
            Boolean(formik.errors.type_of_vehicle)
          }
          helperText={
            formik.touched.type_of_vehicle && formik.errors.type_of_vehicle
          }
        />

        <button
          type="submit"
          className="submit-form-btn"
          aria-labelledby="submit-btn"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default TypeOfVehicle;
