import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { TextField } from "@mui/material";
import axios from "axios";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import * as Yup from "yup";
import { apiRoutes } from "../../utils/apiRoutes";
import Autocomplete from "@mui/material/Autocomplete";

const AddOrganisation = () => {
  const { addOrganisationMasterAPI } = apiRoutes();
  const [nameList, setNameList] = useState([]);
  const [isNewOrganisation, setIsNewOrganisation] = useState(false);
  const validationSchema = Yup.object({
    // name: Yup.string().required("This field is required"),
    // gst: Yup.string().required("This field is required"),
    // instructions: Yup.string().required("This field is required"),
    // phone: Yup.string().required("This field is required"),
    // website: Yup.string().required("This field is required"),
    // email: Yup.string().required("This field is required"),
    // pan: Yup.string().required("This field is required"),
    // status: Yup.array()
    //   .required("This field is required")
    //   .min(1, "At least one status is required"),
    branches: Yup.array()
      .of(
        Yup.object().shape({
          branch: Yup.string().required("Branch name is required"),
          default: Yup.boolean(),
        })
      )
      .test(
        "default-branch",
        "Only one branch can be set as default",
        function (value) {
          const defaultBranches = value.filter((branch) => branch.default);
          return defaultBranches.length <= 1;
        }
      ),
  });

  useEffect(() => {
    async function fetchData() {
      const res = await axios.get(
        "http://13.201.226.15:9002/api/getImporterList/23-24"
      );
      setNameList(res.data);
    }
    fetchData();
  }, []);

  const formik = useFormik({
    initialValues: {
      name: "",
      gst: "",
      instructions: "",
      branches: [
        {
          branch: "",
          default: false,
        },
      ],
      phone: "",
      website: "",
      email: "",
      pan: "",
      status: [],
    },

    // validationSchema: validationSchema,

    onSubmit: async (values, { resetForm }) => {
      console.log(values);

      const res = await axios.post(addOrganisationMasterAPI, values);
      alert(res.data.message);
      // resetForm();
    },
  });

  const handleCheckboxChange = (label) => {
    const isChecked = formik.values.status.includes(label);
    let updatedStatus;

    if (isChecked) {
      updatedStatus = formik.values.status.filter((item) => item !== label);
    } else {
      updatedStatus = [...formik.values.status, label];
    }

    formik.setFieldValue("status", updatedStatus);
  };

  const addBranch = () => {
    formik.setValues({
      ...formik.values,
      branches: [
        ...formik.values.branches,
        {
          branch: "",
          default: false,
        },
      ],
    });
  };

  const handleOrganisationNameChange = (event, value) => {
    formik.setFieldValue("name", value.importer);
  };

  const handleSetDefaultBranch = (index) => {
    const updatedBranches = formik.values.branches.map((branch, i) => ({
      ...branch,
      default: i === index,
    }));
    formik.setFieldValue("branches", updatedBranches);
  };

  return (
    <div className="form-container">
      <form onSubmit={formik.handleSubmit} className="register-form">
        <h3>Add Organisation</h3>
        <FormControlLabel
          control={
            <Checkbox
              checked={isNewOrganisation}
              onChange={(e) => setIsNewOrganisation(e.target.checked)}
            />
          }
          label="New Organisation"
        />
        {!isNewOrganisation ? (
          <Autocomplete
            disablePortal
            options={Array.isArray(nameList) ? nameList : []}
            getOptionLabel={(option) => option.importer}
            width="100%"
            renderInput={(params) => (
              <TextField {...params} label="Select Organisation" />
            )}
            id="tyre_no"
            name="tyre_no"
            onChange={handleOrganisationNameChange}
            style={{ marginBottom: "15px" }}
          />
        ) : (
          <TextField
            margin="dense"
            variant="outlined"
            fullWidth
            id="name"
            name="name"
            label="Name"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
        )}

        <TextField
          margin="dense"
          variant="outlined"
          fullWidth
          id="gst"
          name="gst"
          label="GST Number"
          value={formik.values.gst}
          onChange={formik.handleChange}
          error={formik.touched.gst && Boolean(formik.errors.gst)}
          helperText={formik.touched.gst && formik.errors.gst}
        />
        <TextField
          margin="dense"
          variant="outlined"
          fullWidth
          id="instructions"
          name="instructions"
          label="Instructions"
          value={formik.values.instructions}
          onChange={formik.handleChange}
          error={
            formik.touched.instructions && Boolean(formik.errors.instructions)
          }
          helperText={formik.touched.instructions && formik.errors.instructions}
        />

        {formik.values.branches.map((branch, index) => (
          <div key={index}>
            <TextField
              margin="dense"
              variant="outlined"
              fullWidth
              id={`branches[${index}].branch`}
              name={`branches[${index}].branch`}
              label="Branch"
              value={branch.branch}
              onChange={formik.handleChange}
              error={
                formik.touched[`branches[${index}].branch`] &&
                Boolean(formik.errors[`branches[${index}].branch`])
              }
              helperText={
                formik.touched[`branches[${index}].branch`] &&
                formik.errors[`branches[${index}].branch`]
              }
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={branch.default}
                  onChange={() => handleSetDefaultBranch(index)}
                />
              }
              label="Set as default branch"
            />
          </div>
        ))}

        <button className="submit-form-btn" type="button" onClick={addBranch}>
          Add Branch
        </button>
        <TextField
          margin="dense"
          variant="outlined"
          fullWidth
          id="phone"
          name="phone"
          label="Phone"
          value={formik.values.phone}
          onChange={formik.handleChange}
          error={formik.touched.phone && Boolean(formik.errors.phone)}
          helperText={formik.touched.phone && formik.errors.phone}
        />
        <TextField
          margin="dense"
          variant="outlined"
          fullWidth
          id="website"
          name="website"
          label="Website"
          value={formik.values.website}
          onChange={formik.handleChange}
          error={formik.touched.website && Boolean(formik.errors.website)}
          helperText={formik.touched.website && formik.errors.website}
        />
        <TextField
          margin="dense"
          variant="outlined"
          fullWidth
          id="email"
          name="email"
          label="Email"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
        <TextField
          margin="dense"
          variant="outlined"
          fullWidth
          id="pan"
          name="pan"
          label="PAN Number"
          value={formik.values.pan}
          onChange={formik.handleChange}
          error={formik.touched.pan && Boolean(formik.errors.pan)}
          helperText={formik.touched.pan && formik.errors.pan}
        />
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                checked={formik.values.status.includes("Shipper")}
                onChange={() => handleCheckboxChange("Shipper")}
              />
            }
            label="Shipper"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={formik.values.status.includes("Consignee")}
                onChange={() => handleCheckboxChange("Consignee")}
              />
            }
            label="Consignee"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={formik.values.status.includes("Services")}
                onChange={() => handleCheckboxChange("Services")}
              />
            }
            label="Services"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={formik.values.status.includes("Carrier")}
                onChange={() => handleCheckboxChange("Carrier")}
              />
            }
            label="Carrier"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={formik.values.status.includes("Global")}
                onChange={() => handleCheckboxChange("Global")}
              />
            }
            label="Global"
          />
        </FormGroup>
        <button
          type="submit"
          className="submit-form-btn"
          aria-labelledby="submit-btn"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default AddOrganisation;
