import React from "react";
import { useFormik } from "formik";
import { TextField } from "@mui/material";
import axios from "axios";
import { validationSchema } from "../../schema/master/driverDetailsSchema";
import { apiRoutes } from "../../utils/apiRoutes";

const DriverDetails = (props) => {
  const { addDriverDetailsAPI } = apiRoutes();
  const date = new Date();
  const convertedDate = date
    .toLocaleDateString()
    .split("/")
    .reverse()
    .join("-");

  const formik = useFormik({
    initialValues: {
      driver_name: "",
      driver_phone: "",
      driver_license: "",
      license_validity: convertedDate,
      driver_address: "",
      joining_date: convertedDate,
      blood_group: "",
      driver_photo: "",
      license_photo: "",
    },

    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      const res = await axios.post(addDriverDetailsAPI, values);
      resetForm();
      alert(res.data.message);
    },
  });

  const handleDriverPhotoUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      // Set the formik values with the base64 string
      formik.setFieldValue("driver_photo", reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleLicensePhotoUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      // Set the formik values with the base64 string
      formik.setFieldValue("license_photo", reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="form-container">
      <form onSubmit={formik.handleSubmit} className="register-form">
        <h3>Add Driver Details</h3>
        <TextField
          margin="dense"
          variant="outlined"
          fullWidth
          id="driver_name"
          name="driver_name"
          label="Driver Name"
          value={formik.values.driver_name}
          onChange={formik.handleChange}
          error={
            formik.touched.driver_name && Boolean(formik.errors.driver_name)
          }
          helperText={formik.touched.driver_name && formik.errors.driver_name}
        />

        <TextField
          margin="dense"
          variant="outlined"
          fullWidth
          id="driver_phone"
          name="driver_phone"
          label="Driver Phone"
          value={formik.values.driver_phone}
          onChange={formik.handleChange}
          error={
            formik.touched.driver_phone && Boolean(formik.errors.driver_phone)
          }
          helperText={formik.touched.driver_phone && formik.errors.driver_phone}
        />

        <TextField
          type="text"
          margin="dense"
          variant="outlined"
          fullWidth
          id="driver_license"
          name="driver_license"
          label="License Number"
          value={formik.values.driver_license}
          onChange={formik.handleChange}
          error={
            formik.touched.driver_license &&
            Boolean(formik.errors.driver_license)
          }
          helperText={
            formik.touched.driver_license && formik.errors.driver_license
          }
        />

        <TextField
          type="date"
          margin="dense"
          variant="outlined"
          fullWidth
          id="license_validity"
          name="license_validity"
          label="License Validity"
          value={formik.values.license_validity}
          onChange={formik.handleChange}
          error={
            formik.touched.license_validity &&
            Boolean(formik.errors.license_validity)
          }
          helperText={
            formik.touched.license_validity && formik.errors.license_validity
          }
        />

        <TextField
          type="text"
          margin="dense"
          variant="outlined"
          fullWidth
          id="driver_address"
          name="driver_address"
          label="Driver Address"
          value={formik.values.driver_address}
          onChange={formik.handleChange}
          error={
            formik.touched.driver_address &&
            Boolean(formik.errors.driver_address)
          }
          helperText={
            formik.touched.driver_address && formik.errors.driver_address
          }
        />

        <TextField
          type="date"
          margin="dense"
          variant="outlined"
          fullWidth
          id="joining_date"
          name="joining_date"
          label="Joining Date"
          value={formik.values.joining_date}
          onChange={formik.handleChange}
          error={
            formik.touched.joining_date && Boolean(formik.errors.joining_date)
          }
          helperText={formik.touched.joining_date && formik.errors.joining_date}
        />

        <TextField
          type="text"
          margin="dense"
          variant="outlined"
          fullWidth
          id="blood_group"
          name="blood_group"
          label="Blood Group"
          value={formik.values.blood_group}
          onChange={formik.handleChange}
          error={
            formik.touched.blood_group && Boolean(formik.errors.blood_group)
          }
          helperText={formik.touched.blood_group && formik.errors.blood_group}
        />

        <br />
        <br />

        <label htmlFor="driver_photo">Upload Driver Photo&nbsp;</label>
        <input
          type="file"
          accept="image/*"
          onChange={handleDriverPhotoUpload}
        />
        <br />
        <br />
        <label htmlFor="license_photo">Upload License Photo&nbsp;</label>
        <input
          type="file"
          accept="image/*"
          onChange={handleLicensePhotoUpload}
        />
        <br />

        <button
          type="submit"
          className="submit-form-btn"
          aria-labelledby="submit-btn"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default DriverDetails;
