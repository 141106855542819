import React from "react";
import { useFormik } from "formik";
import { TextField } from "@mui/material";
import axios from "axios";
import { apiRoutes } from "../../utils/apiRoutes";
import * as Yup from "yup";

const EngineOilRestock = () => {
  const { restockEngineOilAPI } = apiRoutes();
  const validationSchema = Yup.object({
    quantity: Yup.number().required("This field is required"),
  });

  const formik = useFormik({
    initialValues: {
      quantity: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      console.log(values);
      const res = await axios.post(restockEngineOilAPI, values);
      console.log(res.data);
      resetForm();
      alert(res.data.message);
    },
  });

  return (
    <div className="form-container">
      <form onSubmit={formik.handleSubmit} className="register-form">
        <h3>Engine Oil Restock</h3>
        <TextField
          margin="dense"
          variant="outlined"
          fullWidth
          id="quantity"
          name="quantity"
          label="Quantity"
          value={formik.values.quantity}
          onChange={formik.handleChange}
          error={formik.touched.quantity && Boolean(formik.errors.quantity)}
          helperText={formik.touched.quantity && formik.errors.quantity}
        />

        <button
          type="submit"
          className="submit-form-btn"
          aria-labelledby="submit-btn"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default EngineOilRestock;
