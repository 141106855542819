import React, { useContext } from "react";
import { Button } from "@mui/material";
import { useFormik } from "formik";
import { TextField } from "@mui/material";
import { validationSchema } from "../schema/LoginSchema";
import axios from "axios";
import { UserContext } from "../Context/UserContext";

const LoginForm = () => {
  const { setUser } = useContext(UserContext);

  const formik = useFormik({
    initialValues: {
      // email: "",
      password: "",
    },

    // validationSchema: validationSchema,

    onSubmit: async (values) => {
      console.log(values);
      if (values.password === "SRCCUSER") {
        localStorage.setItem("srccUser", JSON.stringify(true));
        setUser(true);
      } else {
        alert("Wrong Password");
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      {/* <TextField
        size="small"
        margin="dense"
        variant="filled"
        fullWidth
        id="email"
        name="email"
        label="Email"
        value={formik.values.email}
        onChange={formik.handleChange}
        error={formik.touched.email && Boolean(formik.errors.email)}
        helperText={formik.touched.email && formik.errors.email}
        className="login-input"
      /> */}
      <TextField
        type="password"
        size="small"
        margin="dense"
        variant="filled"
        fullWidth
        id="password"
        name="password"
        label="Password"
        value={formik.values.password}
        onChange={formik.handleChange}
        error={formik.touched.password && Boolean(formik.errors.password)}
        helperText={formik.touched.password && formik.errors.password}
        className="login-input"
      />

      <Button
        // disabled={!otpSent}
        fullWidth
        type="submit"
        className="submit-form-btn"
        aria-label="login-btn"
      >
        Login
      </Button>

      {/* {!props.forgotPassword && (
        <p
          style={{
            float: "right",
            fontWeight: 900,
            marginTop: "10px",
            cursor: "pointer",
          }}
          onClick={() => props.setForgotPassword(true)}
        >
          Forgot password?
        </p>
      )} */}
    </form>
  );
};

export default LoginForm;
