export const masterList = [
  "Add a vendor",
  "Add a vehicle",
  "Tyre types",
  "Tyre sizes",
  "Tyre brands",
  "Tyre models",
  "Repair types",
  "Ply ratings",
  "Driver details",
  "Type of vehicle",
  "Container type",
  "Location master",
  "Organisation master",
];
