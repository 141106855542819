import "./App.scss";
import { useState } from "react";
import { Container } from "react-bootstrap";
import NavbarComponent from "./components/NavbarComponent";
import { ThemeProvider } from "@mui/material/styles";
import useMuiTheme from "./customHooks/useMuiTheme";
import LoginPage from "./pages/LoginPage";
import { UserContext } from "./Context/UserContext";

function App() {
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("srccUser"))
  );
  const muiTheme = useMuiTheme();

  function equivalentValue(char) {
    const equivalences = {
      A: 10,
      B: 12,
      C: 13,
      D: 14,
      E: 15,
      F: 16,
      G: 17,
      H: 18,
      I: 19,
      J: 20,
      K: 21,
      L: 23,
      M: 24,
      N: 25,
      O: 26,
      P: 27,
      Q: 28,
      R: 29,
      S: 30,
      T: 31,
      U: 32,
      V: 34,
      W: 35,
      X: 36,
      Y: 37,
      Z: 38,
      1: 1,
      2: 2,
      3: 3,
      4: 4,
      5: 5,
      6: 6,
      7: 7,
      8: 8,
      9: 9,
      0: 0,
    };
    return equivalences[char];
  }

  function calculateCheckDigit(containerNumber) {
    if (containerNumber.length !== 10) {
      return null;
    }

    const weightingFactors = [1, 2, 4, 8, 16, 32, 64, 128, 256, 512];
    let total = 0;

    for (let i = 0; i < containerNumber.length; i++) {
      total += equivalentValue(containerNumber[i]) * weightingFactors[i];
    }

    const subTotal = Math.floor(total / 11);
    const checkDigit = total - subTotal * 11;

    return checkDigit;
  }

  function validateContainerNumber(containerNumber) {
    const pattern = /^[A-Z]{4}[0-9]{6}$/;
    return pattern.test(containerNumber);
  }

  function isValidContainer(containerNumber) {
    if (containerNumber.length !== 11) {
      return false;
    }

    if (validateContainerNumber(containerNumber.slice(0, 10))) {
      const checkDigit = calculateCheckDigit(containerNumber.slice(0, 10));
      return checkDigit === parseInt(containerNumber[10], 10);
    }
    return false;
  }

  // Test cases
  console.log(isValidContainer("BICU1234565")); // Valid test case with check digit 0

  return (
    <UserContext.Provider value={{ user, setUser }}>
      <ThemeProvider theme={muiTheme}>
        <div className="App">
          {!user ? (
            <>
              <LoginPage />
            </>
          ) : (
            <Container fluid style={{ padding: 0 }}>
              <NavbarComponent />
            </Container>
          )}
        </div>
      </ThemeProvider>
    </UserContext.Provider>
  );
}

export default App;
