import React from "react";
import ReactApexChart from "react-apexcharts";
import useVendorChart from "../../customHooks/useVendorChart";

function Vendor() {
  const vendorChart = useVendorChart();

  return (
    <div className="chart">
      <h5>Vendor</h5>

      <ReactApexChart
        options={vendorChart.options}
        series={vendorChart.series}
        type="bar"
        height={430}
        width="100%"
      />
    </div>
  );
}

export default Vendor;
