import * as yup from "yup";

export const validationSchema = yup.object({
  driver_name: yup
    .string("Enter driver name")
    .required("Driver name is required"),
  driver_phone: yup
    .string("Enter driver phone")
    .required("Driver phone is required"),
  driver_license: yup
    .string("Enter license no")
    .required("License no is required"),
  license_validity: yup
    .string("Enter license validity")
    .required("License validity is required"),
  driver_address: yup
    .string("Enter driver address")
    .required("Driver address is required"),
});
