import React from "react";
import { useFormik } from "formik";
import { TextField } from "@mui/material";
import axios from "axios";
import { validationSchema } from "../../schema/master/tyreTypesSchema";
import { apiRoutes } from "../../utils/apiRoutes";

const TyreTypes = (props) => {
  const { addTyreTypeAPI } = apiRoutes();
  const formik = useFormik({
    initialValues: {
      tyre_type: "",
    },

    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      console.log(values);
      const res = await axios.post(addTyreTypeAPI, values);
      resetForm();
      alert(res.data.message);
    },
  });

  return (
    <div className="form-container">
      <form onSubmit={formik.handleSubmit} className="register-form">
        <h3>Add a Tyre Type</h3>
        <TextField
          margin="dense"
          variant="outlined"
          fullWidth
          id="tyre_type"
          name="tyre_type"
          label="Tyre Type"
          value={formik.values.tyre_type}
          onChange={formik.handleChange}
          error={formik.touched.tyre_type && Boolean(formik.errors.tyre_type)}
          helperText={formik.touched.tyre_type && formik.errors.tyre_type}
        />

        <button
          type="submit"
          className="submit-form-btn"
          aria-labelledby="submit-btn"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default TyreTypes;
