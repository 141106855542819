import React, { useState } from "react";
import { TextField } from "@mui/material";
import { MenuItem } from "@mui/material";
import GenerateLrReport from "./GenerateLrReport";
import ViewAllLr from "./ViewAllLr";

function LrReport() {
  const [masterType, setMasterType] = useState("Generate LR Report");
  const [branch, setBranch] = useState("all");
  const [year, setYear] = useState("24-25");

  const handleBranchChange = (e) => {
    setBranch(e.target.value);
  };

  const masterComponent = () => {
    switch (masterType) {
      case "Generate LR Report":
        return <GenerateLrReport branch={branch} />;
      case "View All LR":
        return <ViewAllLr branch={branch} year={year} />;
      default:
        return null;
    }
  };

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "" }}>
        <TextField
          select
          size="large"
          margin="normal"
          variant="outlined"
          label="Select Branch"
          value={branch}
          onChange={handleBranchChange}
          sx={{ width: "300px", marginLeft: "20px" }}
        >
          <MenuItem value="all">Select</MenuItem>
          <MenuItem value="SND">ICD Sanand</MenuItem>
          <MenuItem value="KHD">ICD Khodiyar</MenuItem>
          <MenuItem value="HZR">Hazira</MenuItem>
          <MenuItem value="MND">Mundra</MenuItem>
          <MenuItem value="SCH">ICD Sachana</MenuItem>
          <MenuItem value="BRD">Baroda</MenuItem>
          <MenuItem value="AIR">Airport</MenuItem>
        </TextField>

        {masterType === "View All LR" && (
          <TextField
            select
            size="large"
            margin="normal"
            variant="outlined"
            label="Select Year"
            value={year}
            onChange={(e) => setYear(e.target.value)}
            sx={{ width: "200px", marginLeft: "20px" }}
          >
            <MenuItem value="24-25">24-25</MenuItem>
            <MenuItem value="23-24">23-24</MenuItem>
          </TextField>
        )}

        <button
          style={{
            height: "50px",
            padding: "0 10px",
            outline: "none",
            border: "none",
            backgroundColor: "#273041",
            color: "#fff",
            cursor: "pointer",
            position: "fixed",
            right: 160,
            zIndex: 100,
          }}
          onClick={() => setMasterType("View All LR")}
        >
          View All LR
        </button>

        <button
          style={{
            height: "50px",
            padding: "0 10px",
            outline: "none",
            border: "none",
            backgroundColor: "#273041",
            color: "#fff",
            cursor: "pointer",
            position: "fixed",
            right: 30,
            zIndex: 100,
          }}
          onClick={() => setMasterType("Generate LR Report")}
        >
          Generate LR
        </button>
      </div>

      <div style={{ height: "80vh" }}>{masterComponent()}</div>
    </div>
  );
}

export default LrReport;
