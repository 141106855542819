import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Sidebar from "./Sidebar";
import { Routes, Route } from "react-router-dom";
import sidebarBg from "../assets/images/sidebar-bg.webp";
import Redirect from "./Redirect";
import { SwipeableDrawer } from "@mui/material";
import NewTyre from "./NewTyre";
import TyreReplacement from "./TyreReplacement";
import TyreBlast from "./TyreBlast";
import Dashboard from "./Dashboard";
import TyreDetails from "./TyreDetails";
import Master from "./Master";
import TyreFitting from "./TyreFitting";
import TyreRepairs from "./TyreRepairs";
import TyreRetreading from "./TyreRetreading";
import DriverAssignment from "./DriverAssignment";
import UploadRTO from "./UploadRTO";
import RTO from "./RTO";
import TruckDetails from "./TruckDetails";
import EngineOilDashboard from "./engineOil/EngineOilDashboard";
import EnigneOilTable from "./engineOil/EnigneOilTable";
import LrReport from "../components/lrReport/LrReport";

const drawerWidth = 250;
const drawerPaperStyles = {
  backgroundColor: "#252e3e",
  backgroundImage: `url(${sidebarBg})`,
  backgroundAttachment: "fixed",
  backgroundPosition: "left 0 bottom 0 !important",
  backgroundSize: "250px !important",
  backgroundRepeat: "no-repeat",
  padding: "0 20px",
};
const drawerStyles = {
  "& .MuiDrawer-paper": {
    boxSizing: "border-box",
    width: drawerWidth,
  },
};

function ResponsiveDrawer() {
  const [mobileOpen, setMobileOpen] = React.useState(false);

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            width: { lg: `calc(100% - ${drawerWidth}px)` },
            ml: { lg: `${drawerWidth}px` },
            backgroundColor: "rgba(249, 250, 251, 0.3)",
            backdropFilter: "blur(6px) !important",
            boxShadow: "none",
          }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={() => setMobileOpen(!mobileOpen)}
              sx={{ mr: 2, display: { lg: "none" } }}
            >
              <MenuIcon sx={{ color: "#000" }} />
            </IconButton>
            <div style={{ flex: 1, alignItems: "center" }}>
              <img
                src={require("../assets/images/topbar-logo.webp")}
                alt="logo"
                height="27.86px"
                width="200px"
              />
            </div>
          </Toolbar>
        </AppBar>

        <Box
          component="nav"
          sx={{ width: { lg: drawerWidth }, flexShrink: { lg: 0 } }}
          aria-label="mailbox folders"
        >
          {/* Drawer mobile */}
          <SwipeableDrawer
            PaperProps={{
              sx: drawerPaperStyles,
            }}
            variant="temporary"
            open={mobileOpen}
            onOpen={() => setMobileOpen(!mobileOpen)}
            onClose={() => setMobileOpen(!mobileOpen)}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{ ...drawerStyles, display: { xs: "block", lg: "none" } }}
          >
            <Sidebar />
          </SwipeableDrawer>

          {/* Drawer desktop */}
          <Drawer
            PaperProps={{
              sx: drawerPaperStyles,
            }}
            variant="permanent"
            sx={{
              ...drawerStyles,
              display: { xs: "none", lg: "block" },
            }}
            open
          >
            <Sidebar />
          </Drawer>
        </Box>

        {/* Content */}
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            width: {
              lg: `calc(100% - ${drawerWidth}px)`,
              backgroundColor: "rgb(249, 250, 251)",
              height: "100vh",
              overflow: "scroll",
              padding: "0 24px",
            },
          }}
        >
          <Toolbar />
          <br />

          <Routes>
            <Route exact path="/" element={<Redirect />} />
            <Route exact path="/dashboard" element={<Dashboard />} />
            <Route exact path="/master" element={<Master />} />
            <Route exact path="new-tyre" element={<NewTyre />} />
            <Route exact path="tyre-fitting" element={<TyreFitting />} />
            <Route
              exact
              path="tyre-replacement"
              element={<TyreReplacement />}
            />
            <Route exact path="tyre-repairs" element={<TyreRepairs />} />
            <Route exact path="tyre-retreading" element={<TyreRetreading />} />
            <Route exact path="tyre-blast" element={<TyreBlast />} />
            <Route
              exact
              path="driver-assignment"
              element={<DriverAssignment />}
            />
            <Route exact path="tyre-details" element={<TyreDetails />} />
            <Route exact path="truck-details" element={<TruckDetails />} />
            <Route exact path="upload-rto" element={<UploadRTO />} />
            <Route exact path="rto" element={<RTO />} />
            <Route exact path="engine-oil" element={<EngineOilDashboard />} />
            <Route exact path="lr-report" element={<LrReport />} />
          </Routes>
        </Box>
      </Box>
    </>
  );
}

export default ResponsiveDrawer;
