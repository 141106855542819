import React, { useState } from "react";
import axios from "axios";
import { TextField } from "@mui/material";
import { generateLrPdf } from "../../utils/generateLrPdf";

function ViewAllLr(props) {
  const [data, setData] = useState();
  const [tr, setTr] = useState("");
  const [trData, setTrData] = useState([]);

  async function getData() {
    const res = await axios.post(`http://3.111.214.24:9002/api/viewAllLr`, {
      tr,
      year: props.year,
      branch: props.branch,
    });

    setData(res.data);
    setTrData(res.data.containers);
  }

  return (
    <div style={{ width: "100%", overflow: "scroll" }}>
      <br />
      <br />
      <br />
      <TextField
        fullWidth
        variant="filled"
        label="Search LR"
        onChange={(e) => setTr(e.target.value)}
      />
      <br />
      <br />
      <button
        style={{
          padding: "5px 10px",
          border: "none",
          outline: "none",
          backgroundColor: "#273041",
          color: "#fff",
          marginLeft: "10px",
        }}
        onClick={() => getData()}
      >
        Search
      </button>
      {data && (
        <button
          style={{
            padding: "5px 10px",
            border: "none",
            outline: "none",
            backgroundColor: "#273041",
            color: "#fff",
            marginLeft: "10px",
          }}
          onClick={() => generateLrPdf(trData, data)}
        >
          Generate PDF
        </button>
      )}
    </div>
  );
}

export default ViewAllLr;
