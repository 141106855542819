import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { apiRoutes } from "../../utils/apiRoutes";
import axios from "axios";

function EnigneOilTable() {
  const [rows, setRows] = useState([]);
  const { viewEngineOilAPI } = apiRoutes();

  useEffect(() => {
    async function getData() {
      const res = await axios(`${viewEngineOilAPI}`);
      setRows(res.data);
    }

    getData();
  }, []);

  const columns = [
    {
      field: "_id",
      sortable: false,
      hide: true,
      headerName: "ID",
    },

    {
      field: "date",
      sortable: false,
      headerName: "Date",
      width: 200,
      align: "center",
    },

    {
      field: "driver",
      sortable: false,
      headerName: "Driver",
      width: 200,
      align: "center",
    },

    {
      field: "truck_no",
      sortable: false,
      headerName: "Truck Number",
      width: 200,
      align: "center",
    },

    {
      field: "quantity",
      sortable: false,
      headerName: "Quantity",
      width: 200,
      align: "center",
    },

    {
      field: "odometer",
      sortable: false,
      headerName: "Odometer",
      width: 200,
      align: "center",
    },

    {
      field: "location",
      sortable: false,
      headerName: "Location",
      width: 200,
      align: "center",
    },
  ];
  return (
    <>
      <h3>Engine Oil Distribution</h3>
      <br />
      <DataGrid
        getRowId={(row) => row._id}
        sx={{
          height: "80%",
          "& .MuiDataGrid-row:hover": {
            backgroundColor: "#f8f5ff",
          },
        }}
        className="table expense-table"
        headerAlign="center"
        rows={rows}
        columns={columns}
        stickyHeader
        rowsPerPageOptions={[25]}
        getRowHeight={() => "auto"}
        pageSize={25}
        autoHeight={false}
        disableSelectionOnClick
        disableColumnMenu
      />
    </>
  );
}

export default EnigneOilTable;
