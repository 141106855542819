import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { TextField } from "@mui/material";
import axios from "axios";
import { apiRoutes } from "../../utils/apiRoutes";
import Autocomplete from "@mui/material/Autocomplete";
import * as Yup from "yup";

const EngineOilDistribution = () => {
  const { getTruckNumberAPI } = apiRoutes();
  const [truckNo, setTruckNo] = useState([]);
  const [data, setData] = useState();
  const { engineOilDistributionAPI, getEngineOilStockAPI } = apiRoutes();

  const validationSchema = Yup.object({
    quantity: Yup.number().required("This field is required"),
    driver: Yup.string().required("This field is required"),
    truck_no: Yup.string().required("This field is required"),
    date: Yup.string().required("This field is required"),
  });

  const formik = useFormik({
    initialValues: {
      truck_no: "",
      quantity: "",
      date: new Date().toISOString().slice(0, 10),
      driver: "",
      odometer: "",
      location: "",
    },

    validationSchema: validationSchema,

    onSubmit: async (values, { resetForm }) => {
      if (data < values.quantity) {
        alert("Engine Oil Quantity is greater than Stock");
      } else {
        const res = await axios.post(engineOilDistributionAPI, values);
        console.log(res.data);
        alert(res.data.message);
        resetForm();
      }
    },
  });

  useEffect(() => {
    async function getTruckNumber() {
      const res = await axios.get(getTruckNumberAPI);
      setTruckNo(res.data.map((item) => item.truck_no));
    }

    async function getEngineOilStock() {
      const res = await axios.get(getEngineOilStockAPI);

      setData(res.data[0].quantity);
    }

    getTruckNumber();
    getEngineOilStock();
  }, []);

  const handleTruckNoChange = (event, value) => {
    formik.setFieldValue("truck_no", value);
  };

  return (
    <div className="form-container">
      <form onSubmit={formik.handleSubmit} className="register-form">
        <h3>Engine Oil Distribution</h3>
        <strong>Engine Oil Available in Stock: {data} litres</strong>
        <br />
        <br />
        <Autocomplete
          disablePortal
          options={Array.isArray(truckNo) ? truckNo : []}
          getOptionLabel={(option) => (option ? option : "")}
          width="100%"
          renderInput={(params) => (
            <TextField {...params} label="Select truck number" />
          )}
          id="truck_no"
          name="truck_no"
          onChange={(event, value) => handleTruckNoChange(event, value, formik)}
          value={formik.values.truck_no}
          style={{ marginBottom: "15px" }}
        />
        <TextField
          margin="dense"
          variant="outlined"
          fullWidth
          id="quantity"
          name="quantity"
          label="Quantity"
          value={formik.values.quantity}
          onChange={formik.handleChange}
          error={formik.touched.quantity && Boolean(formik.errors.quantity)}
          helperText={formik.touched.quantity && formik.errors.quantity}
        />
        <TextField
          type="date"
          margin="dense"
          variant="outlined"
          fullWidth
          id="date"
          name="date"
          label="Date"
          value={formik.values.date}
          onChange={formik.handleChange}
          error={formik.touched.date && Boolean(formik.errors.date)}
          helperText={formik.touched.date && formik.errors.date}
        />
        <TextField
          margin="dense"
          variant="outlined"
          fullWidth
          id="driver"
          name="driver"
          label="Driver"
          value={formik.values.driver}
          onChange={formik.handleChange}
          error={formik.touched.driver && Boolean(formik.errors.driver)}
          helperText={formik.touched.driver && formik.errors.driver}
        />
        <TextField
          margin="dense"
          variant="outlined"
          fullWidth
          id="odometer"
          name="odometer"
          label="Odometer"
          value={formik.values.odometer}
          onChange={formik.handleChange}
          error={formik.touched.odometer && Boolean(formik.errors.odometer)}
          helperText={formik.touched.odometer && formik.errors.odometer}
        />
        <TextField
          margin="dense"
          variant="outlined"
          fullWidth
          id="location"
          name="location"
          label="Location"
          value={formik.values.location}
          onChange={formik.handleChange}
          error={formik.touched.location && Boolean(formik.errors.location)}
          helperText={formik.touched.location && formik.errors.location}
        />
        <button
          type="submit"
          className="submit-form-btn"
          aria-labelledby="submit-btn"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default EngineOilDistribution;
