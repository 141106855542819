import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { TextField } from "@mui/material";
import axios from "axios";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { apiRoutes } from "../../utils/apiRoutes";
import Autocomplete from "@mui/material/Autocomplete";
import { MenuItem } from "@mui/material";

const InsertAddress = () => {
  const { addOrganisationAddressAPI } = apiRoutes();
  const [nameList, setNameList] = useState([]);
  const [selectedName, setSelectedName] = useState(null);
  const [branchOptions, setBranchOptions] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const res = await axios.get(
        "http://13.201.226.15:8000/api/getOrganisationList"
      );
      setNameList(res.data);
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (selectedName) {
      const selectedOrganisation = nameList.find(
        (organisation) => organisation.name === selectedName
      );
      if (selectedOrganisation) {
        setBranchOptions(
          selectedOrganisation.branches.map((branch) => branch.branch)
        );
      }
    }
  }, [selectedName, nameList]);

  const formik = useFormik({
    initialValues: {
      name: "",
      branch: "",
      addresses: [
        {
          address_type: "",
          address: "",
          default: false,
        },
      ],
    },

    onSubmit: async (values, { resetForm }) => {
      console.log(values);
      const res = await axios.post(addOrganisationAddressAPI, values);
      console.log(res.data);
      alert(res.data.message);
      resetForm();
    },
  });

  const handleOrganisationNameChange = (event, value) => {
    setSelectedName(value ? value.name : null);
    formik.setFieldValue("name", value ? value.name : "");
  };

  const handleCheckboxChange = (index) => {
    const updatedAddresses = formik.values.addresses.map((address, i) => {
      if (i === index) {
        return { ...address, default: true };
      } else {
        return { ...address, default: false };
      }
    });
    formik.setFieldValue("addresses", updatedAddresses);
  };

  const addAddress = () => {
    formik.setFieldValue("addresses", [
      ...formik.values.addresses,
      {
        address_type: "",
        address: "",
        default: false,
      },
    ]);
  };

  return (
    <div className="form-container">
      <form onSubmit={formik.handleSubmit} className="register-form">
        <h3>Add Address</h3>
        <Autocomplete
          disablePortal
          options={Array.isArray(nameList) ? nameList : []}
          getOptionLabel={(option) => option.name}
          width="100%"
          renderInput={(params) => (
            <TextField {...params} label="Select Organisation" />
          )}
          id="tyre_no"
          name="tyre_no"
          onChange={handleOrganisationNameChange}
          style={{ marginBottom: "15px" }}
        />

        <TextField
          fullWidth
          select
          name="branch"
          id="branch"
          size="large"
          margin="normal"
          variant="outlined"
          label="Select Branch"
          value={formik.values.branch}
          onChange={formik.handleChange}
        >
          {branchOptions.map((branch, id) => (
            <MenuItem key={id} value={branch}>
              {branch}
            </MenuItem>
          ))}
        </TextField>

        <br />
        {formik.values.addresses.map((address, index) => (
          <div key={index}>
            <TextField
              margin="dense"
              variant="outlined"
              fullWidth
              id={`addresses[${index}].address_type`}
              name={`addresses[${index}].address_type`}
              label="Address Type"
              value={address.address_type}
              onChange={formik.handleChange}
            />
            <TextField
              margin="dense"
              variant="outlined"
              fullWidth
              id={`addresses[${index}].address`}
              name={`addresses[${index}].address`}
              label="Address"
              value={address.address}
              onChange={formik.handleChange}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={address.default}
                  onChange={() => handleCheckboxChange(index)}
                />
              }
              label="Set as default address"
            />
          </div>
        ))}

        <button
          type="button"
          className="submit-form-btn"
          aria-labelledby="submit-btn"
          onClick={addAddress}
        >
          Add Address
        </button>

        <button
          type="submit"
          className="submit-form-btn"
          aria-labelledby="submit-btn"
          style={{ marginLeft: "10px" }}
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default InsertAddress;
