import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import "../styles/sidebar.scss";
import { sidebarData } from "../assets/data/sidebarData";
import { IconButton, List, ListItem } from "@mui/material"; // Removed ListItemButton
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import { UserContext } from "../Context/UserContext";

function Sidebar() {
  const user = useContext(UserContext);

  return (
    <div className="sidebar">
      <div className="avatar">
        <img
          src={require("../assets/images/sidebar-logo.webp")}
          alt=""
          width={180}
          height={87}
        />
      </div>
      <List>
        {sidebarData.map((val) => {
          const { id, icon, name, url } = val;
          return (
            <ListItem
              disableGutters={true}
              key={id}
              className="sidebar-listItem"
            >
              <NavLink to={url} key={id} className="sidebar-link">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <IconButton
                    sx={{
                      color: "#ffffff9f",
                      border: "none !important",
                    }}
                    aria-label="icon"
                  >
                    {icon}
                  </IconButton>
                  <p className="sidebar-list-text">{name}</p>
                </div>
              </NavLink>
            </ListItem>
          );
        })}
      </List>

      <List>
        <ListItem
          sx={{ textAlign: "left" }}
          className="sidebar-listItem"
          style={{
            padding: "5px 0",
            cursor: "pointer",
          }}
          onClick={() => {
            localStorage.removeItem("srccUser");
            window.location.reload();
          }}
        >
          <div className="sidebar-link">
            <div style={{ display: "flex", alignItems: "center" }}>
              <IconButton sx={{ color: "#ffffff9f" }}>
                <LogoutRoundedIcon />
              </IconButton>
              <p className="sidebar-list-text">Logout</p>
            </div>
          </div>
        </ListItem>
      </List>
    </div>
  );
}

export default Sidebar;
