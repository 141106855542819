import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { TextField } from "@mui/material";
import axios from "axios";
import { validationSchema } from "../../schema/master/vehicleSchema";
import { apiRoutes } from "../../utils/apiRoutes";
import { MenuItem } from "@mui/material";

const Vehicles = () => {
  const [truckTypes, setTruckTypes] = useState([]);
  const { getTypeOfVehicleAPI } = apiRoutes();
  useEffect(() => {
    async function getData() {
      const res = await axios.get(getTypeOfVehicleAPI);
      setTruckTypes(res.data);
    }

    getData();
  }, []);
  const { addVehicleAPI } = apiRoutes();
  const formik = useFormik({
    initialValues: {
      truck_no: "",
      max_tyres: "",
      units: "",
    },

    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      console.log(values);
      const res = await axios.post(addVehicleAPI, values);
      resetForm();
      alert(res.data.message);
    },
  });

  const handleTruckNoChange = (event) => {
    const newValue = event.target.value.toUpperCase(); // Convert to uppercase
    formik.handleChange(event); // Handle Formik's onChange event
    formik.setFieldValue("truck_no", newValue); // Update the formik field value
  };

  return (
    <div className="form-container">
      <form onSubmit={formik.handleSubmit} className="register-form">
        <h3>Add a Vehicle</h3>
        <TextField
          margin="dense"
          variant="outlined"
          fullWidth
          id="truck_no"
          name="truck_no"
          label="Truck Number"
          value={formik.values.truck_no}
          onChange={handleTruckNoChange}
          error={formik.touched.truck_no && Boolean(formik.errors.truck_no)}
          helperText={formik.touched.truck_no && formik.errors.truck_no}
          inputProps={{ maxLength: 10 }}
        />

        <TextField
          select
          margin="dense"
          variant="outlined"
          fullWidth
          id="max_tyres"
          name="max_tyres"
          label="Maximum Tyres"
          value={formik.values.max_tyres}
          onChange={formik.handleChange}
          error={formik.touched.max_tyres && Boolean(formik.errors.max_tyres)}
          helperText={formik.touched.max_tyres && formik.errors.max_tyres}
        >
          {truckTypes.map((type, id) => (
            <MenuItem key={id} value={type}>
              {type}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          type="text"
          margin="dense"
          variant="outlined"
          fullWidth
          id="units"
          name="units"
          label="Units"
          value={formik.values.units}
          onChange={formik.handleChange}
          error={formik.touched.units && Boolean(formik.errors.units)}
          helperText={formik.touched.units && formik.errors.units}
        />

        <button
          type="submit"
          className="submit-form-btn"
          aria-labelledby="submit-btn"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default Vehicles;
